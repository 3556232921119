
@font-face {
    font-family: $msv-font-family-rubik;
    font-display: swap;
    src: url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype");
}

@font-face {
    font-family: $msv-font-family-rubik-regular;
    src: url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-rubik-regular;
    src: url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-rubik-bold;
    src: url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-rubik-bold;
    src: url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-rubik-medium;
    src: url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-rubik-medium;
    src: url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype"),
        url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-beth-ellen;
    font-display: swap;
    src: url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype");
}

@font-face {
    font-family: $msv-font-family-beth-ellen;
    src: url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: $msv-font-family-nothing-you-could-do;
    font-display: swap;
    src: url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: $msv-font-family-nothing-you-could-do;
    src: url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype"),
        url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700");

/* normal */
@font-face {
  font-family: 'tondo';
  font-style: normal;
  font-weight: normal;
  src: url('../../../only/fonts/tondo-regular.woff2') format('woff2'),
    url('../../../only/fonts/tondo-regular.woff') format('woff');
}

/* bold */
@font-face {
  font-family: 'tondo';
  font-style: normal;
  font-weight: bold;
  src: url('../../../only/fonts/tondo-bold.woff2') format('woff2'),
    url('../../../only/fonts/tondo-bold.woff') format('woff');
}

/* light */
@font-face {
  font-family: 'tondo';
  font-style: normal;
  font-weight: 200;
  src: url('../../../only/fonts/tondo-light.woff2') format('woff2'),
    url('../../../only/fonts/tondo-light.woff') format('woff');
}

/* normal */
@font-face {
  font-family: 'giorgiosans';
  font-style: normal;
  font-weight: normal;
  src: url('../../../only/fonts/giorgiosans-medium.woff2') format('woff2'),
    url('../../../only/fonts/giorgiosans-medium.woff') format('woff');
}

/** Generated by FG **/
@font-face {
  font-family: 'BasicSans-Bold';
  src: url('../../../only/fonts/BasicSans-Bold.eot');
  src: local('☺'), url('../../../only/fonts/BasicSans-Bold.woff') format('woff'), url('../../../only/fonts/BasicSans-Bold.ttf') format('truetype'), url('../../../only/fonts/BasicSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'Conv_EuropaBold';
  src: url('../../../only/fonts/EuropaBold.eot');
  src: local('☺'), url('../../../only/fonts/EuropaBold.woff') format('woff'), url('../../../only/fonts/EuropaBold.ttf') format('truetype'), url('../../../only/fonts/EuropaBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'Conv_EuropaRegular';
  src: url('../../../only/fonts/EuropaRegular.eot');
  src: local('☺'), url('../../../only/fonts/EuropaRegular.woff') format('woff'), url('../../../only/fonts/EuropaRegular.ttf') format('truetype'), url('../../../only/fonts/EuropaRegular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'Conv_EuropaLight';
  src: url('../../../only/fonts/EuropaLight.eot');
  src: local('☺'), url('../../../only/fonts/EuropaLight.woff') format('woff'), url('../../../only/fonts/EuropaLight.ttf') format('truetype'), url('../../../only/fonts/EuropaLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: $msv-icon-font-family;
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: swap;
    src: url("#{$msv-font-path}/remixicon.woff2") format("woff2");
    // src: url("../../../webfonts/remixicon.woff2") format("woff2");
}

@font-face {
    font-family: $msv-icon-font-family;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-regular-400.woff') format('woff');
}

@font-face {
    font-family: $msv-icon-font-family;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: $msv-solid-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-solid-900.woff') format('woff');
}

@font-face {
    font-family: 'icomoon';
    src: url('../../../only/fonts/icomoon.eot?rk7t0i');
    src: url('../../../only/fonts/icomoon.eot?rk7t0i#iefix') format('embedded-opentype'),
      url('../../../only/fonts/icomoon.ttf?rk7t0i') format('truetype'),
      url('../../../only/fonts/icomoon.woff?rk7t0i') format('woff'),
      url('../../../only/fonts/icomoon.svg?rk7t0i#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

@font-face {
    font-family: 'Remix Icon';
    src: url('../../../only/fonts/remixicon.eot');
    src: url('../../../only/fonts/remixicon.eot') format('embedded-opentype'),
      url('../../../only/fonts/remixicon.ttf') format('truetype'),
      url('../../../only/fonts/remixicon.woff') format('woff'),
      url('../../../only/fonts/remixicon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
