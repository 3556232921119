@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/icons.scss";

//Variables
$msv-accordion-font-family: $msv-primary-font-family;
$msv-accordion-font-style: normal;
$msv-accordion-height: 68px;
$msv-accordion-font-weight-400: 400;
$msv-accordion-padding: 25px 0 56px 0;
$msv-accordion-product-details-padding-left: 6px;
$ms-product-details-padding-bottom: 40px;
$ms-product-details-padding-top: 20px;

// Style preset
:root {
    // border
    --msv-product-specification-accordion-border: #{$msv-blue};

    // body
    --msv-product-specification-accordion-text-font-color: #{$msv-gray-40};
    --msv-product-specification-accordion-bg-color: #{$msv-white};
    --msv-product-specification-accordion-font-size: #{$msv-font-size-s};
    --msv-product-details-font-size: #{$msv-font-size-m};
    --msv-product-details-text-font-color: var(--msv-font-primary-color);
}

.ms-product-specification {
    color: var(--msv-font-primary-color);

    &__heading {
        display: none;
    }

    &__accordion {
        font-family: $msv-accordion-font-family;
        font-style: $msv-accordion-font-style;
        font-weight: $msv-font-weight-normal;
        padding: $msv-accordion-padding;

        .drawer {
            border-top: 0.5px solid $msv-gray-50;
            background-color: var(--msv-product-specification-accordion-bg-color);

            &__button {
                width: 100%;
                height: $msv-accordion-height;
                background-color: var(--msv-product-specification-accordion-bg-color);
                display: flex;
                justify-content: space-between;
                border: none;
                outline: none;

                &:focus {
                    border: 0.5px solid var(--msv-product-specification-accordion-border);
                }

                &:focus:not(:focus-visible) {
                    outline: none;
                }

                .drawer__buttontext {
                    @include font-body-regular-s();
                    float: left;
                    margin-bottom: auto;
                    margin-top: auto;
                    color: var(--msv-product-specification-accordion-text-font-color);
                }

                .ms-product-specification__drawer-close,
                .ms-product-specification__drawer-open {
                    float: right;
                    line-height: $msv-line-height-m;
                    margin-bottom: auto;
                    margin-top: auto;
                }

                .ms-product-specification__drawer-close {
                    @include add-icon($msv-Add, after);

                    &::after {
                        font-size: $msv-icon-size-m;
                        vertical-align: middle;
                    }
                }

                .ms-product-specification__drawer-open {
                    @include add-icon($msv-Remove, after);

                    &::after {
                        font-size: $msv-icon-size-m;
                        vertical-align: middle;
                    }
                }
            }

            .ms-product-details {
                @include font-body-regular-m();
                background-color: var(--msv-product-specification-accordion-bg-color);
                color: var(--msv-product-details-text-font-color);
                padding-left: $msv-accordion-product-details-padding-left;
                padding-bottom: $ms-product-details-padding-bottom;
                padding-top: $ms-product-details-padding-top;

                .style_number {
                    font-weight: $msv-font-weight-bold;
                }

                &:focus {
                    outline: none;
                }

                ul.ms-product-details-additional-media {
                    list-style: disc;
                    padding-left: 25px;
                }
            }
        }

        .ms-product-specification-title:last-child {
            border-bottom: 0.5px solid $msv-gray-50;
        }
    }
}

// Extend/Custom - TODO: Move into correct extend file/location
$non-mobile: 600px;

.ms-product-specification {
    &__accordion {
        margin: 22px 0;
        padding: 0;

        @media (min-width: $non-mobile) {
            padding: 4px 0 0 0;
            border-top: 1px solid #959595;
        }

        .ms-product-specification-title {
            margin: 12px 0 0 0;
            padding: 0;

            &:last-child {
                border: 0; //Reset
            }
        }

        .drawer {
            margin: 0;
            padding: 0;
            border: 0;
            min-height: 26px;

            @media (min-width: $non-mobile) {
                min-height: 32px;
            } 

            &__button {
                margin: 0;
                padding: 0;
                height: 26px;
                color: #000 !important;

                &:hover, &:focus, &:active {
                    border: none !important; //Reset
                    background: none !important; //Reset
                    box-shadow: none !important; //Reset
                } 

                &text {
                    color: #000 !important;
                    font-size: 14px !important;
                    font-weight: 700 !important;
                    text-transform: uppercase;
                }

                .ms-product-specification__drawer-close:after {
                    content: "\ea4e"; //REF: https://remixicon.com/icon/arrow-down-s-line
                    font-family: "Remix Icon";
                    font-weight: 400;
                    font-size: 16px;
                }

                .ms-product-specification__drawer-open:after {
                    content: "\ea78"; //REF: https://remixicon.com/icon/arrow-up-s-line
                    font-family: "Remix Icon";
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .ms-product-details {
                font-size: 14px;
                font-weight: 400;
                margin: 0;
                padding: 12px 0 10px 0;
            }
        }
    } 
}
