@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/icons.scss";
// @import "../common/02-generic/reset.scss";

$ms-text-block-margin-top: 25px;

@mixin social-media-icon-styling {
    color: $msv-gray-900;
    font-size: 22px;
    margin-right: .5rem;
}

//style presets
:root {
    --msv-text-block-font-color: var(--msv-font-primary-color);
    --msv-text-block-font-size-s: var(--msv-body-font-size-s);
    --msv-text-block-font-size-m: var(--msv-body-font-size-m);
    --msv-text-block-font-size-l: var(--msv-body-font-size-l);
    --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);
}

.ms-text-block {
    @include font-body-regular-m();
    margin-top: $ms-text-block-margin-top;
    padding-bottom: 12px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $msv-bold-font-family;
        margin: 1.5em 0 0.6em 0;
        line-height: 1.2em;
        text-transform: uppercase;
        letter-spacing: 0.84px;
    }

    h1 {
        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-h1-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-h1-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-h1-font-size-m);
        }
    }

    h2 {
        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-h2-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-h2-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-h2-font-size-m);
        }
    }

    h3 {

        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-h3-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-h3-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-h3-font-size-m);
        }
    }

    h4 {
        text-transform: none;

        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-h4-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-h4-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-h4-font-size-m);
        }
    }

    h5 {
        text-transform: none;

        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-h5-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-h5-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-h5-font-size-m);
        }
    }

    h6 {
        text-transform: none;

        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-h6-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-h6-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-h6-font-size-m);
        }
    }


    p, li {
        font-family: $msv-primary-font-family;
        line-height: 1.7em;

        @media (min-width: $msv-breakpoint-l) {
            font-size: var(--msv-body-font-size-l);
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            font-size: var(--msv-body-font-size-s);
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            font-size: var(--msv-body-font-size-m);
        }
    }

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    &.fontsize__small {
        font-size: var(--msv-text-block-font-size-s);
        line-height: $msv-line-height-s;
    }

    &.fontsize__medium {
        font-size: var(--msv-text-block-font-size-m);
        line-height: $msv-line-height-m;
    }

    &.fontsize__large {
        font-size: var(--msv-text-block-font-size-l);
        line-height: $msv-line-height-l;
    }

    &.fontsize__extralarge {
        font-size: var(--msv-text-block-font-size-xl);
        line-height: $msv-line-height-xl;
    }

    a {
        color: var(--msv-text-block-font-color);
        text-decoration: underline;

        &[aria-label="facebook-link"] {
            align-items: center;
            text-decoration: none !important;

            &::before {
                @include msv-icomoon-icon();
                @include social-media-icon-styling();
                content: $ico-Facebook;
            }
        }

        &[aria-label="instagram-link"] {
            align-items: center;
            text-decoration: none !important;

            &::before {
                @include msv-icomoon-icon();
                @include social-media-icon-styling();
                content: $ico-Instagram;
            }
        }

        &[aria-label="tiktok-link"] {
            align-items: center;
            text-decoration: none !important;

            &::before {
                @include msv-icomoon-icon();
                @include social-media-icon-styling();
                content: $ico-TikTok;
            }
        }

        &[aria-label="linkedin-link"] {
            align-items: center;
            text-decoration: none !important;

            &::before {
                @include msv-icomoon-icon();
                @include social-media-icon-styling();
                content: $ico-LinkedIn;
            }
        }
    }

    // MODULE SPECIFIC CLASSES

    &.frontpage-nav-button {
        border: 1px solid black;
        font-size: 14px;
        padding: 3px;
        margin: 0;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    //Selecting table used in style-guide
    &.size-table {
        overflow-x: scroll;
        
        table {

            th {
                width: 100px;
                width: fit-content;
                text-wrap: nowrap;
                padding-right: 20px;
            }

            tr:nth-child(odd) {
                background-color: $msv-ONLY-light-gray;
            }

            td {
                height: 35px;
                text-align: center;
                padding: 0 10px;
            }
        }
    }
}