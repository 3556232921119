@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/02-generic/image.scss";
@import "../common/01-tools/layout.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/02-generic/button.scss";
@import "../common/00-settings/icons.scss";
// @import "../common/02-generic/reset.scss";

$msv-content-block-error-text-margin: 20px 0;
$msv-content-block-error-details-margin-desktop: auto;
$msv-content-block-error-details-margin-tablet: auto;
$msv-content-block-error-details-margin-mobile: auto;

:root {
    // Text theme
    --msv-content-block-error-textheme-dark: var(--msv-content-block-textheme-dark);
    --msv-content-block-error-textheme-light: var(--msv-content-block-textheme-light);
}


//   UNIQUE CLASSES FROM CMS

.ms-content-block {
    &[data-m-layout="hero-error"] {
        height: 100vh;
        position: relative;

        .ms-content-block__image,
        .ms-content-block__image img {
            width: $msv-width-full;
        }

        .ms-content-block__image svg {
            width: $msv-width-full;
        }

        .ms-content-block__details {
            margin: $msv-content-block-error-details-margin-desktop;
            display: flex;
            flex-direction: column;
            align-content: center;
            position: absolute;
            text-align: left;
            align-items: flex-start;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            pointer-events: none;
            padding: 12px;
            height: 100vh;

            @media (max-width: $msv-breakpoint-m) {
                margin: $msv-content-block-error-details-margin-mobile;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                margin: $msv-content-block-error-details-margin-tablet;
            }

            .ms-content-block__title {
                @include font-callout-bold-l();
                font-size: 106px;
                line-height: 1;
                color: var(--msv-content-block-error-textheme-light);
                margin-bottom: 10px;


                @media (max-width: $msv-breakpoint-m) {
                    // @include font-callout-bold-s();
                    color: var(--msv-content-block-error-textheme-light);
                    // font-size: 36px;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    // @include font-callout-bold-m();
                    color: var(--msv-content-block-error-textheme-light);
                }
            }

            .ms-content-block__text {
                margin: $msv-content-block-error-text-margin;
                font-size: 18px;

                @include font-body-regular-m();
                color: var(--msv-content-block-error-textheme-light);
            }

            .msc-cta__primary {
                @include primary-button-light();
                pointer-events: auto;
            }

            .msc-cta__secondary {
                pointer-events: auto;
            }
        }

        &.textplacement__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;
        }

        &.textplacement__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;
            align-items: flex-end;
        }

        &.texttheme__light .ms-content-block__title,
        &.texttheme__light .ms-content-block__text {
            color: var(--msv-content-block-error-textheme-light);
        }

        &.texttheme__dark .ms-content-block__title,
        &.texttheme__dark .ms-content-block__text {
            color: var(--msv-content-block-error-textheme-dark);
            text-shadow: none;
        }

        &.texttheme__dark .msc-cta__primary {
            @include secondary-button-dark();
        }

        &.texttheme__light .msc-cta__primary {
            @include secondary-button-light();
        }

        /**
        * Text over image by default only in modern browsers.
        * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
        */

        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }
        }
    }
}


.maintenance.ms-content-block {
    &[data-m-layout="hero-error"] .ms-content-block__details .ms-content-block__title {
        font-size: 72px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 36px;
        }
    }
}