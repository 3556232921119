@import "../00-settings/responsive-breakpoints";


$msv-layout-h-padding-s: 6px;
$msv-layout-h-padding-m: 40px;
$msv-layout-h-padding-l: 80px;
$msv-layout-h-padding-xl: 120px;
$msv-layout-v-padding-s: 6px;
$msv-layout-v-padding-m: 40px;
$msv-layout-v-padding-l: 64px;
$msv-layout-v-padding-xl: 80px;
$msv-layout-page-clamp-width: $msv-breakpoint-xl;
$msv-layout-container-width: $msv-breakpoint-xl;

// Container level padding
$msv-layout-container-padding-l: 12px 36px 0px 36px;
$msv-layout-container-padding-m: 12px 12px 0px 12px;
$msv-layout-container-padding-s: 12px 12px 0px 12px;

// Single column width for desktop (s), tablet (m) and mobile (l).
$msv-column-width-s: 8.333%;
$msv-column-width-m: 12.5%;
$msv-column-width-l: 25%;

// Limit the max width of an element with margin on either side of the element

@mixin msv-layout-page-clamp {
    max-width: $msv-layout-page-clamp-width;
    margin: auto;
}

// Prevent content from touch the edge of the screen with padding on either side of the element

@mixin msv-layout-page-side-padding {
    padding-left: $msv-layout-h-padding-s;
    padding-right: $msv-layout-h-padding-s;

    @media screen and (min-width: $msv-breakpoint-m) {
        padding-left: $msv-layout-h-padding-m;
        padding-right: $msv-layout-h-padding-m;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        padding-left: $msv-layout-h-padding-l;
        padding-right: $msv-layout-h-padding-l;
    }

    @media screen and (min-width: $msv-breakpoint-xl) {
        padding-left: $msv-layout-h-padding-xl;
        padding-right: $msv-layout-h-padding-xl;
    }
}

@mixin msv-layout-content-block-padding {
    padding: $msv-layout-v-padding-s $msv-layout-h-padding-s;

    @media screen and (min-width: $msv-breakpoint-m) {
        padding: $msv-layout-v-padding-m $msv-layout-h-padding-m;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        padding: $msv-layout-v-padding-l $msv-layout-h-padding-l;
    }

    @media screen and (min-width: $msv-breakpoint-xl) {
        padding: $msv-layout-v-padding-xl $msv-layout-h-padding-xl;
    }
}

@mixin container-bottom-margin {
    margin-bottom: 20px;

    @media screen and (min-width: $msv-breakpoint-m) {
        margin-bottom: 20px;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        margin-bottom: 20px;
    }
}

// Adds bootstrap grid "col-6" CSS
@mixin only-col6-override {
    flex: 0 0 50%;
    max-width: 50%;
}

@mixin add-gutters {
    .row {
        margin: -6px;
    }

    [class^="col-"] {
        padding: 6px;
    }
}

#main div:last-child.default-container {
    margin-bottom: 40px !important;
} 

#main div:first-child {
    margin-top: 0 !important;
}

.default-container {
    @include container-bottom-margin();

    &.front-page_product-category-image-nav,
    &.front-page_product-category-nav,
    &.front-page_product-category-image-2col-nav {
        padding: $msv-layout-container-padding-s;

        @media screen and (min-width: $msv-breakpoint-m) {
            padding: $msv-layout-container-padding-m;
        }

        @media screen and (min-width: $msv-breakpoint-l) {
            padding: $msv-layout-container-padding-m;
        }

        @media screen and (min-width: $msv-breakpoint-xl) {
            padding: $msv-layout-container-padding-m;
        }
    }
}

header .default-container,
footer .default-container {
    margin-bottom: 0 !important;
}

// header .default-container .row div:last-child {
//     display: none;
// }

// header .default-container.fixed .row div:last-child {
//     height: 123px;
// }

body:has(header .default-container.fixed) {
    main {
        margin-top: 123px;
    }
}

footer .default-container {
    padding: 0;
    margin: 0;

    &.default-container {
        padding: 0;
        margin: 0;
    }
}


.front-page_product-category-image-nav {
    margin-bottom: 0;

    @include add-gutters();

    .ms-content-block {
        padding: 0px;
        aspect-ratio: 1/1;
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        .row {
            // max-width: 1440px;
            margin: -6px;
            // padding-left: 60px;
            // padding-right: 60px;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m - 1) {

        .row {
            margin: -6px;
        }

        [class^="col-"] {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

}

//MODULE SPECIFIC CLASSES

.front-page_product-category-nav {

    @include add-gutters();

    @media screen and (max-width: $msv-breakpoint-m - 1) {
        [class^="col-"] {
            @include only-col6-override();
        }
    }

}

.front-page_product-category-image-2col-nav {
    margin-bottom: 0;

    @include add-gutters();

    @media screen and (max-width: $msv-breakpoint-m - 1) {
        [class^="col-"] {
            @include only-col6-override();
        }
    }

}

.front-page_member-section {
    margin-bottom: 0px;
}