$search-result-container-product-discount-box-height: 32px;
$search-result-container-product-discount-box-width: fit-content;

.discount-box {
    position: absolute;
    top: calc(5% + 0px);
    // right: calc(70% + 0px);
    //TODO: display differently for different sizes
    //@media (max-width: $msv-breakpoint-ml-max) {
    //    top: calc(75% + 0px);
    //}
    height: $search-result-container-product-discount-box-height;
    width: $search-result-container-product-discount-box-width;
    background-color: $msv-sale-pink;
    // padding-left: -20px;

    p {
        text-align: center;
        line-height: $search-result-container-product-discount-box-height;
        display: inline-block;
        color: $msv-white;
        font-size: 20px;
    }
}

.discount-box__member {
    box-sizing: content-box;
    position: absolute;
    top: calc(5% + 0px);
    // right: calc(70% + 20px);
    //TODO: display differently for different sizes
    //@media (max-width: $msv-breakpoint-ml-max) {
    //    top: calc(75% + 0px);
    //}
    height: $search-result-container-product-discount-box-height;
    width: $search-result-container-product-discount-box-width;
    background-color: #37373773;
    left: 1px;
    
    p {
        text-align: center;
        line-height: $search-result-container-product-discount-box-height;
        display: inline-block;
        color: $msv-white;
        font-size: 14px;
        white-space: nowrap;
        padding: 0 8px;
    }
}

.msc-price {
    .msc-price__discount {
        color: $msv-sale-pink;

        &__member {
            // margin-left: 4px;
            height: $search-result-container-product-discount-box-height;
            width: $search-result-container-product-discount-box-width;
            color: $msv-white;
            background-color: $msv-gray-140;
        }
    }

    .msc-price__salesagreementprice {

        button.msc-price__salesagreementprompt_expanded,
        button.msc-price__salesagreementprompt_collapsed {
            background: transparent;
            border: none;
            color: $msv-blue;
            margin-top: 34px;
        }

        .msc-price__salesagreementprompt_expanded::after,
        .msc-price__salesagreementprompt_collapsed::after {
            color: $msv-gray-20;
            line-height: 16px;
            width: 16px;
            margin-top: 2px;
            position: absolute;
        }

        .msc-price__salesagreementprompt_collapsed {
            @include add-icon($msv-ChevronDown, after);
        }

        .msc-price__salesagreementprompt_expanded {
            @include add-icon($msv-ChevronUp, after);
        }

        .msc-price__salesagreementdetail {
            margin-top: 6px;
        }
    }
}