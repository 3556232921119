$msv-breakpoint-xs: 0;
$msv-breakpoint-s: 0;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-lg: 1366px;
$msv-breakpoint-xl: 1440px;

$msv-container-min-width-m: 769px;

$msv-width-full: 100%;
$msv-width-half: 50%;
$msv-width-threequarter: 75%;
$msv-width-quarter: 25%;
$msv-height-full: 100%;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-ml: 1024px;
$msv-container-max-width-l: 1440px;
$msv-container-max-width-xxl:1920px;

$msv-breakpoint-xxxsm: 321px;
$msv-breakpoint-xxsm: 376px;
$msv-breakpoint-iphone: 391px;
$msv-breakpoint-iphone-8: 416px;
$msv-breakpoint-xsm: 426px;
$msv-breakpoint-ssm: 480px;
$msv-breakpoint-sm: 576px;
$msv-breakpoint-surface: 912px;
$msv-breakpoint-ipad-9: 810px;
$msv-breakpoint-ml: 1366px;
$msv-breakpoint-1600: 1600px;
$msv-breakpoint-xxl: 1920px;
$msv-breakpoint-xl-max: 1439px;
$msv-breakpoint-ml-max:1200px;
$msv-breakpoint-l-max:1023px;
$msv-container-min-width-l:1025px;
$msv-breakpoint-min-ml: 1367px;
$msv-breakpoint-min-width-xxl: 1800px;
$msv-container-max-width-xl:1439px;