@import 'colors';
@import 'font';
@import 'font-face';
@import '../02-generic/reset';
@import 'icons';
@import 'responsive-breakpoints';
// @import "presets";

// :root {
//     // Headings H1
//     --msv-h1-font-size-l: #{$msv-title-font-size-l};
//     --msv-h1-font-size-m: #{$msv-title-font-size-m-s};
//     --msv-h1-font-size-s: #{$msv-title-font-size-xs};
//     --msv-h1-line-height-l: #{$msv-title-line-height-l};
//     --msv-h1-line-height-m: #{$msv-title-line-height-l};
//     --msv-h1-line-height-s: #{$msv-title-line-height-s};

//     // Headings H2
//     --msv-h2-font-size-l: #{$msv-title-font-size-m};
//     --msv-h2-font-size-m: #{$msv-title-font-size-m-s};
//     --msv-h2-font-size-s: #{$msv-title-font-size-m-s};
//     --msv-h2-line-height-l: #{$msv-title-line-height-m};
//     --msv-h2-line-height-m: #{$msv-title-line-height-m-s};
//     --msv-h2-line-height-s: #{$msv-title-line-height-m-s};

//     // Headings H3
//     --msv-h3-font-size-l: #{$msv-title-font-size-s};
//     --msv-h3-font-size-m: #{$msv-title-font-size-s};
//     --msv-h3-font-size-s: #{$msv-title-font-size-s};
//     --msv-h3-line-height-l: #{$msv-title-line-height-s};
//     --msv-h3-line-height-m: #{$msv-title-line-height-s};
//     --msv-h3-line-height-s: #{$msv-title-line-height-s};

//     // Headings H4
//     --msv-h4-font-size-l: #{$msv-title-font-size-s};
//     --msv-h4-font-size-m: #{$msv-title-font-size-s};
//     --msv-h4-font-size-s: #{$msv-title-font-size-s};
//     --msv-h4-line-height-l: #{$msv-title-line-height-s};
//     --msv-h4-line-height-m: #{$msv-title-line-height-s};
//     --msv-h4-line-height-s: #{$msv-title-line-height-s};

//     // Headings H5
//     --msv-h5-font-size-l: #{$msv-title-font-size-xs};
//     --msv-h5-font-size-m: #{$msv-title-font-size-xs};
//     --msv-h5-font-size-s: #{$msv-title-font-size-xs};
//     --msv-h5-line-height-l: #{$msv-title-line-height-xs};
//     --msv-h5-line-height-m: #{$msv-title-line-height-xs};
//     --msv-h5-line-height-s: #{$msv-title-line-height-xs};

//     // Headings H6
//     --msv-h6-font-size-l: #{$msv-title-font-size-xs};
//     --msv-h6-font-size-m: #{$msv-title-font-size-xs};
//     --msv-h6-font-size-s: #{$msv-title-font-size-xs};
//     --msv-h6-line-height-l: #{$msv-title-line-height-xs};
//     --msv-h6-line-height-m: #{$msv-title-line-height-xs};
//     --msv-h6-line-height-s: #{$msv-title-line-height-xs};

//     // Special Callouts
//     --msv-callout-font-size-l: 300px;
//     --msv-callout-font-size-m: #{$msv-tablet-title-font-size-xl};
//     --msv-callout-font-size-s: 2.5rem;
//     --msv-callout-line-height-l: 6.5rem;
//     --msv-callout-line-height-m: #{$msv-tablet-title-line-height-xl};
//     --msv-callout-line-height-s: #{$msv-mobile-title-line-height-s};

//     // Body
//     --msv-body-font-color: #{$msv-gray-20};
//     --msv-body-font-size-xs: #{$msv-font-size-xs};
//     --msv-body-font-size-s: #{$msv-font-size-s};
//     --msv-body-font-size-m: #{$msv-font-size-m};
//     --msv-body-font-size-l: #{$msv-font-size-l};
//     --msv-body-font-size-xl: #{$msv-font-size-xl};
//     --msv-body-font-size-xxl: #{$msv-font-size-xxl};
//     --msv-body-line-height-xs: #{$msv-line-height-xs};
//     --msv-body-line-height-s: #{$msv-line-height-s};
//     --msv-body-line-height-m: #{$msv-line-height-m};

//     // Weight
//     --msv-font-weight-bold: #{$msv-font-weight-bold};
//     --msv-font-weight-normal: #{$msv-font-weight-normal};
//     --msv-font-weight-light: #{$msv-font-weight-light};
//     --msv-font-weight-heavy: #{$msv-font-weight-heavy};

//     // Background
//     --msv-bg-color: #{$msv-white};
//     --msv-body-bg-color: #{$msv-white};

//     // Brand accents
//     --msv-accent-brand-color: #{$msv-primary};
//     --msv-accent-brand-alt-color: #{$msv-primary-hover};
//     --msv-accent-secondary-btn: #{$msv-secondary-hover};

//     // Font
//     --msv-font-primary-color: #{$msv-gray-20};
//     --msv-font-secondary-color: #{$msv-white};
//     --msv-font-disabled-color: #{$msv-gray-50};

//     // Events
//     --msv-button-primary-rest-color: #{$msv-primary};
//     --msv-button-primary-hover-color: #{$msv-primary-hover};
//     --msv-button-primary-pressed-color: #{$msv-gray-60};
//     --msv-button-primary-disabled-color: #{$msv-gray-50};
//     --msv-button-secondary-rest-color: #{$msv-white};
//     --msv-button-secondary-hover-color: #{$msv-white-20};
//     --msv-button-secondary-pressed-color: #{$msv-white-10};
//     --msv-button-secondary-disabled-color: #{$msv-white};

//     // Modal
//     --msv-modal-bg-color: #{$msv-white};
//     --msv-modal-font-color: var(--msv-font-primary-color);
// }
