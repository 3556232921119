$msv-account-profile-heading-margin-bottom: 32px;

$msv-account-profile-section-margin-right: 20px;
$msv-account-profile-section-margin-right-30: 30px;
$msv-account-profile-section-margin-right-m: 0;
$msv-account-profile-section-margin-bottom: 30px;
$msv-account-profile-section-heading-margin-bottom: 4px;
$msv-account-profile-section-description-margin-bottom: 6px;

$msv-account-profile-preference-margin-top: 20px;
$msv-account-profile-preference-heading-margin-bottom: 10px;
$msv-account-profile-preference-description-margin-bottom: 0px;

$msv-account-profile-toggle-button-icon-size: 28px;
$msv-account-profile-toggle-button-padding: 0 5px;

$msv-account-profile-attributes-min-width: 260px;
$msv-account-profile-attributes-section-margin-bottom: 12px;
$msv-account-profile-attributes-tile-heading-margin-bottom: 10px;
$msv-account-profile-attributes-tile-heading-icon-padding: 5px;
$msv-account-profile-attributes-toggle-min-height: 50px;
$msv-account-profile-attributes-toggle-button-height: 30px;
$msv-account-profile-attributes-toggle-button-disable-opacity: 0.6;
$msv-account-profile-attributes-button-right-margin: 10px;

$msv-reward-pink: #c40062;
$msv-reward-pink-II: #e50064;

//style presets
:root {
    --msv-account-profile-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-font-color: var(--msv-font-primary-color);

    // heading
    --msv-account-profile-heading-font-color: var(--msv-font-primary-color);
    --msv-account-profile-heading-font-size: var(--msv-body-font-size-l);

    // title
    --msv-account-profile-title-font-size: var(--msv-body-font-size-xl);

    // button
    --msv-account-profile-toggle-btn-font-color: var(--msv-accent-brand-color);
    --msv-account-profile-toggle-btn-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-toggle-size: #{$msv-account-profile-toggle-button-icon-size};
}

// .ms-account-profile {
//     @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-font-size), $msv-line-height-m);
//     color: var(--msv-account-profile-font-color);
//     align-items: flex-start;
//     display: inline-flex;
//     flex-direction: unset;
//     justify-content: center;

//     .ms-account-loyalty-tile__heading {
//         color: var(--msv-account-profile-heading-font-color);
//     }

//     &-wrapper {
//         margin-right: $msv-account-profile-section-margin-right;
//     }

//     &__heading {
//         @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-title-font-size), $msv-line-height-xl);
//         margin-bottom: $msv-account-profile-heading-margin-bottom;
//     }

//     &__section {
//         margin-bottom: $msv-account-profile-section-margin-bottom;

//         &-heading {
//             @include font-content(var(--msv-font-weight-heavy), var(--msv-account-profile-heading-font-size), $msv-line-height-l);
//             color: var(--msv-account-profile-heading-font-color);
//             margin-bottom: $msv-account-profile-section-heading-margin-bottom;
//         }

//         &-description {
//             margin-bottom: $msv-account-profile-section-description-margin-bottom;
//         }

//         &-links {
//             text-decoration: underline;
//         }

//         &-link {
//             @include vfi();
//             color: var(--msv-account-profile-font-color);
//         }
//     }

//     &__preference {
//         margin-top: $msv-account-profile-preference-margin-top;

//         &-heading {
//             @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-font-size), $msv-line-height-m);
//             color: var(--msv-account-profile-font-color);
//             margin-bottom: $msv-account-profile-preference-heading-margin-bottom;
//         }

//         &-description {
//             margin-bottom: $msv-account-profile-preference-description-margin-bottom;
//         }
//     }

//     &__toggle-wrapper {
//         font-size: var(--msv-account-profile-toggle-btn-font-size);
//         display: inline-flex;
//         justify-content: center;
//         align-items: center;
//     }

//     &__toggle-button {
//         font-size: var(--msv-account-profile-toggle-size);
//         background-color: transparent;
//         padding: $msv-account-profile-toggle-button-padding;

//         &:focus {
//             @include basic-outline-offset();
//         }
//     }

//     &__toggle-enable {
//         .ms-account-profile__toggle-enable-text {
//             font-weight: var(--msv-font-weight-bold);
//         }
//     }

//     &__toggle-disable {
//         .ms-account-profile__toggle-disable-text {
//             font-weight: var(--msv-font-weight-bold);
//         }
//     }

//     &__toggle-enable-button {
//         @include add-icon($msv-ToggleRight);
//         color: var(--msv-account-profile-toggle-btn-font-color);
//     }

//     &__toggle-disable-button {
//         @include add-icon($msv-ToggleRight);

//         &::before {
//             transform: scale(-1, 1);
//         }
//     }

//     .ms-account-profile__attributes {
//         min-width: $msv-account-profile-attributes-min-width;

//         &-tile__heading {
//             // @include add-icon($msv-ListAlt, before);
//             @include font-content(var(--msv-font-weight-bold), var(--msv-account-landing-tile-heading-size), $msv-line-height-l);
//             color: var(--msv-account-profile-heading-font-color);
//             margin-bottom: $msv-account-profile-attributes-tile-heading-margin-bottom;
//             color: pink;

//             &:before {
//                 padding-right: $msv-account-profile-attributes-tile-heading-icon-padding;
//             }
//         }

//         &__section {
//             margin-bottom: $msv-account-profile-attributes-section-margin-bottom;

//             &-heading {
//                 font-size: var(--msv-account-profile-font-size);
//                 font-weight: var(--msv-font-weight-normal);
//                 color: var(--msv-account-profile-heading-font-color);
//                 margin-bottom: $msv-account-profile-section-heading-margin-bottom;
//             }

//             &__toggle {
//                 min-height: $msv-account-profile-attributes-toggle-min-height;
//             }

//             &__toggle-wrapper {
//                 font-size: var(--msv-account-profile-toggle-btn-font-size);
//                 display: inline-flex;
//                 justify-content: center;
//                 align-items: center;
//             }

//             &__toggle-button {
//                 font-size: var(--msv-account-profile-toggle-size);
//                 background-color: transparent;
//                 padding: $msv-account-profile-toggle-button-padding;
//                 height: $msv-account-profile-attributes-toggle-button-height;

//                 &:focus {
//                     @include basic-outline-offset();
//                 }
//             }

//             &__toggle-button.disabled {
//                 opacity: $msv-account-profile-attributes-toggle-button-disable-opacity;
//             }

//             &__toggle-enable {
//                 .ms-account-profile__attributes__section__toggle-enable-text {
//                     font-weight: var(--msv-font-weight-bold);
//                 }
//             }

//             &__toggle-disable {
//                 .ms-account-profile__attributes__section__toggle-disable-text {
//                     font-weight: var(--msv-font-weight-bold);
//                 }
//             }

//             &__toggle-enable-button {
//                 @include add-icon($msv-ToggleRight);
//                 color: var(--msv-account-profile-toggle-btn-font-color);
//             }

//             &__toggle-disable-button {
//                 @include add-icon($msv-ToggleRight);

//                 &::before {
//                     transform: scale(-1, 1);
//                 }
//             }
//         }

//         &-error-label {
//             color: var(--msv-error-color);
//         }

//         &_input,
//         &_select {
//             width: 100%;
//         }

//         &_input-edit-false,
//         &_select-edit-false {
//             border: none;
//             background: transparent;
//             color: var(--msv-account-profile-font-color);
//         }

//         &_select-edit-false {
//             -webkit-appearance: none;
//             -moz-appearance: none;
//             text-indent: 1px;
//             text-overflow: '';
//             opacity: 1;
//             color: var(--msv-account-profile-font-color);
//         }

//         &-edit-button,
//         &-save-button,
//         &-cancel-button {
//             @include vfi();
//             color: var(--msv-account-profile-font-color);
//             background-color: transparent;
//             font-size: var(--msv-body-font-size-m);
//             text-decoration: underline;
//             margin-right: $msv-account-profile-attributes-button-right-margin;
//             padding: 0;
//             height: auto;
//         }
//     }

//     @media screen and (max-width: $msv-breakpoint-m) {
//         display: flex;
//         flex-direction: column;

//         &-wrapper {
//             margin-right: $msv-account-profile-section-margin-right-m;
//         }
//     }
// }

.bs-account-profile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 2%;
    min-height: 500px;

    @media screen and (max-width: $msv-container-max-width-m) {
        flex-direction: column;
        justify-content: center;
    }

    h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .bs-profile-box {
        padding-right: $msv-account-profile-section-margin-right;
        width: 50%;
        // max-width: 30%;
        // min-width: 30%;
        
        @media (max-width: $msv-container-max-width-ml) {
            max-width: 100%;
            padding-right: 0;
            margin-bottom: 32px;
        }

        .point-box {
            background-color: $msv-white;
            padding: 35px 25px;
            color: $msv-reward-pink-II;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);


            @media (min-width:$msv-container-min-width-l) {
                min-height: 329.1px
            }

            .logo-img-box {
                position: relative;
                margin: 30px auto;
                width: 70%;
                color: $msv-black;


                .svg-img-box {
                    padding: 2px 0;
                }
            }

            h4 {
                font-size: $msv-font-size-m;
                font-family: $msv-primary-font-family;
                color: $msv-reward-pink-II;
            }

            strong {
                font-family: $msv-bold-font-family;
                margin-bottom: 15px;
                display: block;
                color: $msv-reward-pink-II;
            }

            h4,
            strong {
                color: $msv-gray-900;
                color: $msv-reward-pink-II;
            }
        }

        ul.profile-links {
            margin-bottom: 15px;

            li {
                font-size: $msv-font-size-s;
                text-transform: uppercase;
                margin-bottom: 5px;

                a,
                button {
                    &:empty {
                        display: none;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                button {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    text-transform: uppercase;
                    color: var(--msv-font-primary-color);
                    font-size: $msv-font-size-s;

                    &:focus {
                        outline: none;
                        background-color: transparent;
                        box-shadow: none;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &:last-child {
                li {
                    font-size: $msv-font-size-s;
                    text-transform: uppercase;
                    margin-bottom: 5px;

                    a {
                        font-family: $msv-bold-font-family;
                    }
                }
            }
        }

        .ms-account-profile__section {
            margin-bottom: 0;
        }
    }

    .benefit-wrapper {
        max-width: 100%;
        display: flex;
        // @media (max-width:$msv-container-max-width-ml) {
        //     // flex-wrap: wrap;
        //     // max-width: 55%;
        // }
        // @media (max-width: $msv-container-max-width-ml) {
        //     max-width: 100%;
        // }

        @media (min-width: $msv-breakpoint-m) {
            width: 50%;
            justify-content: space-around;
        }

        .reward-card-parent-wrapper{
            max-width: 80%;
            @media (max-width:$msv-container-max-width-ml) {
                max-width: 100%;
            }

            @media (max-width: $msv-breakpoint-l) {
                display: flex;
            }

            @media (max-width: $msv-breakpoint-m) {
                flex-direction: column;
            }

            .reward-card-wrapper {
                padding-right: 20px;

                @media (max-width:$msv-container-max-width-ml) {
                    padding-right: 0;
                }

                @media (max-width: $msv-breakpoint-l) {
                    width: 100%;
                    &:nth-child(even) {
                        padding-left: 12px;
                        padding-right: 0;
                    }

                    &:nth-child(odd) {
                        padding-right: 12px;
                    }
                }
                
                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                    
                    &:nth-child(even) {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    &:nth-child(odd) {
                        padding-right: 0;
                    }
                }
            }
        }

        .reward-dummy-vocher {
            max-width: 45%;
            min-height: 240px;
            background-color: $msv-white;
            // max-height: 330px;
            // height: 354px;
            border: 0.5px solid $msv-gray-300;
            // box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.3);
            
            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                // margin-bottom: 32px;
            }

            @media screen and (min-width: $msv-breakpoint-xl) {

            }
            
            .reward-bg-image {
                // max-height: 250px;
                height: 100%;
                overflow: hidden;
                
                @media (max-width:$msv-container-max-width-ml) {
                    max-height: 175px;
                }
                
                @media (max-width: $msv-container-max-width-m) {
                    max-height: 200px;
                }
                
                @media (max-width: $msv-breakpoint-m) {
                    max-height: 237px;
                }
                
                img {
                    width: 100%;
                }
            }

            .reward-bg-box {
                background-color: $msv-white;

                .reward-card-box {
                    background-color: rgba(255, 255, 255, 0.6);
                    // margin: -222px 30px 0;
                    margin: -160px 30px 0;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    min-width: auto;


                    .bonus-heading {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 30px 0;

                        span {
                            display: block;
                            text-align: center;
                            font-size: $msv-font-size-l;
                            font-family: $msv-bold-font-family;
                            line-height: $msv-line-height-l;
                        }

                        > span {
                            &:first-child {
                                font-family: $msv-secondary-font-family;
                                font-size: $msv-font-size-ml;
                            }
                        }
                    }

                    .expiry-date {
                        font-size: $msv-font-size-s;
                    }
                }
            }
        }

        .voucer-wrapper {
            display: flex;
            width: 100%;
            @media (max-width:$msv-container-max-width-ml) {
                margin-bottom: 45px;
            }
            @media (max-width: $msv-breakpoint-l) {
                max-width: 100%;
            }

            @media (max-width: $msv-breakpoint-m) {
                flex-direction: column;
            }

            .voucher-card-box {
                max-width: 231px;
                position: relative;

                @media (max-width:$msv-breakpoint-l) {
                    min-width: 262px;
                    max-width: 100%;
                }
                @media (max-width: $msv-breakpoint-m) {
                    margin-bottom: 75px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .voucher-content {
                    position: absolute;
                    width: 80%;
                    min-height: 205px;
                    background-color: rgba(255, 255, 255, 0.5);
                    bottom: 120px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-transform: uppercase;
                    text-align: center;
                    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
                    padding: 20px;
                    @media (max-width:$msv-container-max-width-ml) {
                        bottom: -45px;
                    }
                    h3 {
                        margin-bottom: 15px;
                        font-size: $msv-font-size-xl;
                    }

                    strong,
                    h3 {
                        font-family: $msv-bold-font-family;
                        display: inline-block;
                    }

                    small {
                        position: absolute;
                        bottom: 10px;
                        width: 100%;
                        left: 0;
                        font-weight: 400;
                    }
                }

                img {
                    width: 100%;
                }

                &:first-child {
                    margin-right: 20px;
                    @media (max-width: $msv-breakpoint-m) {
                        margin-right: 0;
                    }
                }
            }
        }

        .default-voucher-wrapper {
            // padding: 0 20px;
            // max-height: 380px;
            // overflow-y: auto;
            width: 70%;
            padding: 0 15px;
            margin: 0 auto;

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
            }

            @media (min-width: $msv-breakpoint-m) {
                width: 70%;
            }

            //NOTE: backgound image should perhaps be controlled through CMS
            .reward-card-box {
                padding: 20px 25px;
                box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
                background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/mgdsrmgddc/imageFileData/MDCBXx?ver=62ea');
                margin-bottom: 16px;
                display: inline-flex;
                align-items: center;
                width: 100%;
                height: 110px;
                background-size: cover;
                
                @media (min-width: $msv-breakpoint-xl) {
                    height: 135px
                }
                
                .logo-box {
                    padding: 30px 15px 30px 0;
                    margin-right: 15px;
                    position: relative;
                    width: 130px;
                    
                    // &::after {
                        //     content: '';
                        //     width: 1px;
                        //     height: 100%;
                        //     right: 0;
                        //     position: absolute;
                        //     top: 0;
                        //     background-color: $msv-black;
                        // }
                        
                    }
                    
                    .card-detail {
                        text-align: center;
                        width: 100%;
                        text-transform: uppercase;
                        
                    p > span {
                        font-size: 0.7rem;

                        @media screen and (min-width: $msv-breakpoint-lg) {
                            font-size: 1rem;
                        }
                    }
                }

                h3 {
                    font-family: $msv-bold-font-family;
                    margin-bottom: 15px;
                    font-size: $msv-font-size-l;
                }

                p {
                    margin-bottom: 15px;
                    font-family: $msv-secondary-font-family;
                    // @include font-heading-h4-m();
                    
                    span {
                        display: block;
                    }

                    &:last-child {
                        margin-bottom: 0;
                        font-size: $msv-font-size-xs;
                    }
                }

                @media (min-width: $msv-container-max-width-ml) and (max-width: $msv-container-max-width-l) {
                    h3 {
                        margin-bottom: 12px;
                        font-size: $msv-font-size-l;
                    }

                    p {
                        font-size: $msv-font-size-s;
                        margin-bottom: 12px;
                    }
                }
            }

            .voucher-card-box {
                box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
                // border: 0.5px solid black;
                background: #fff;
                margin-bottom: 25px;
                display: flex;
                min-height: 100px;
                max-height: 150px;
                min-width: 100%;
                overflow: hidden;
                position: relative;
                align-items: center;
                justify-content: space-evenly;

                .voucher-logo-box {
                    // max-width: 350px;
                    width: 100%;

                    img {
                        object-fit: cover;
                    }
                }

                .voucher-card-detail {
                    // position: absolute;
                    background-color: rgba(255, 255, 255, 0.6);
                    width: 50%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    padding: 0 10px 10px 10px;

                    p {
                        text-align: center;
                        // padding-top: 15px;
                        // font-size: $msv-font-size-s;
                        font-size: 16px;
                        font-family: $msv-secondary-font-family;

                        span {
                            display: block;
                        }
                    }

                    .voucher-logo-box {
                        width: 80px;
                    }
                }
            }
            &::-webkit-scrollbar,
            &::-webkit-scrollbar-button {
                width: 5px;
            }

            &::-webkit-scrollbar-button {
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #eee;
                border: thin solid #dfdfdf;
                box-shadow: 0px 0px 3px #dfdfdf inset;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #323130;
                border: thin solid #323130;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #7d7d7d;
            }

            .reward-card-wrapper {
                .reward-card-box {
                    background-image: none !important;
                    background-color: $msv-reward-pink-II;
                    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
                    min-height: 150px;
                    color: $msv-white;


                    .logo-box {
                        padding: 30px 15px 30px 0;
                        margin-right: 15px;
                        position: relative;
                        width: 130px;
    
                        &::after {
                            content: '';
                            width: 1px;
                            height: 100%;
                            right: 0;
                            position: absolute;
                            top: 0;
                            background-color: $msv-black;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        .benefit-wrapper {
            .voucer-wrapper,
            .reward-card-wrapper {
                .voucher-card-box {
                    .voucher-content {
                        padding: 25px 15px 40px;
                        height: 90%;
                    }
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        flex-wrap: wrap;

        .bs-profile-box {
            width: 100%;
            padding-right: 0;
        }

        .benefit-wrapper {
            width: 100%;
            flex-wrap: wrap;
            gap: 30px;

            .voucer-wrapper,
            .reward-card-wrapper {
                width: 100%;
                padding-right: 0;

                &:nth-child(even) {
                    padding-left: 0;
                    padding-right: 0;
                }

                &:nth-child(odd) {
                    padding-right: 0;
                }

                .voucher-card-box {
                    .voucher-content {
                        padding: 15px 5px 38px 5px;
                        min-height: 75%;
                        height: auto;

                        h3 {
                            font-size: $msv-font-size-l;
                        }
                    }
                }
            }
        }
    }

    .account-details {
        width: 70%;

        h3 {
            font-family: $msv-primary-font-family;
            font-size: 18px;
            color: #333;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 14px;
            font-family: $msv-bold-font-family;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 15px;

                label {
                    text-transform: uppercase;
                    margin: 0;
                }

                p {
                    background-color: $msv-gray-50;
                    padding: 10px;
                }

                input {
                    width: 100%;
                    border: 1px solid $msv-gray-300;
                    padding: 5px;
                }
            }
        }

        .addressBox {
            background-color: $msv-gray-50;
            padding: 10px;
            margin-bottom: 15px;

            textarea {
                width: 100%;
                border: 1px solid $msv-gray-300;
                padding: 5px;

                &:focus {
                    outline: none;
                    border: none;
                }
            }
        }

        .spinner-border {
            display: block;
            margin: 10px auto;
        }

        .btn-primary {
            margin-bottom: 15px;
        }

        .back-button {
            margin-bottom: 15px;
        }

        .editProfile {
            position: absolute;
            right: 0;
            top: 0;
            text-decoration: underline;
            background-color: transparent;
            padding: 0;
            border: none;
        }

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    &.without-coupons {
        @media (max-width: $msv-container-max-width-m) {
            flex-direction: column;
            flex-wrap: initial;
        }
        
        .bs-profile-box {
            // max-width: 35%;
            // padding-right: 30px;
            @media (max-width: $msv-breakpoint-ml-max) {
                padding-right: 15px;
                .point-box {
                    padding: 17px 20px;
                    
                    h3 {
                        font-size: $msv-font-size-xl;
                    }

                    h4 {
                        font-size: $msv-font-size-s;
                        strong {
                            margin-bottom: 10px;
                        }
                    
                        &:last-child{
                            strong {
                                margin-bottom: 0;
                            }
                        }
                    }
                    
                    .logo-img-box {
                        width: 50%;
                        margin: 25px auto;
                    }
                }
            }

            @media (max-width: $msv-container-max-width-m) {
                max-width: 100%;
                padding-right: 0;
                margin-bottom: 30px;
            }
        }

        .benefit-wrapper {
            max-width: 70%;

            @media (max-width: $msv-breakpoint-m) {
                max-width: 100%;
                flex-direction: column;
                gap: 30px;
            }

            @media screen and (min-width: $msv-breakpoint-xl) {
                column-gap: 3.5rem;
            }

            .reward-dummy-vocher {
                max-width: 48%;
                padding-right: 0;
                // box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
                // margin-bottom: 30px;
                // min-height: 330px;
                
                @media (max-width: $msv-breakpoint-ml-max) {
                    max-width: 45%;
                    margin-right: 15px;
                    min-height: auto;
                }
                
                @media (max-width: $msv-container-max-width-ml) {
                    height: 236px;
                }
                    
                @media (max-width: $msv-container-max-width-m) {
                    max-width: 44%;
                    margin-right: 25px;
                    // min-height: 268px;
                }
                
                @media (max-width: $msv-breakpoint-m) {
                    max-width: 100%;
                    padding-right: 0;
                    // margin-bottom: 25px;
                    margin-right: 0;
                    height: 237px;
                }



                .reward-bg-box {
                    .reward-card-box {
                        min-height: 195px;
                        min-width: auto;
                        justify-content: center;
                        // box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);

                        
                        @media (max-width: $msv-container-max-width-ml) {
                            min-height: fit-content;
                            top: 80px;
                        }
                        
                        .bonus-heading{
                            span {
                                @media (max-width: $msv-breakpoint-ml-max) {
                                    font-size: $msv-font-size-s;
                                }
                            }
                        }
                        
                        .expiry-date {
                            @media (max-width: $msv-breakpoint-ml-max) {
                                font-size: $msv-font-size-11;
                            }
                        }
                    }
                }
            }
            .default-voucher-wrapper{
                // max-width: calc(100% - 49%);
                
                .reward-card-box {
                   
                    .logo-box{
                        @media (max-width: $msv-breakpoint-ml-max) {
                            width: 100px;
                            padding: 25px 15px 25px 0;
                        }
                    }
                }

                .voucher-card-box {
                    @media (max-width: $msv-breakpoint-ml-max) {
                        min-height: 110px;
                        min-width: 245px;
                    }
                    @media (max-width: $msv-container-max-width-m) {
                        min-height: 110px;
                        max-height: 119px;
                        height: 110px;
                    }
                    > .voucher-logo-box{
                        @media (max-width: $msv-breakpoint-m) {
                            max-width: 100%;
                        }

                        > img {
                            width: 100%;
                        }
                    }
                    .voucher-card-detail{
                        @media (max-width: $msv-breakpoint-ml-max) {
                            p {
                                padding-top: 10px;
                                font-size: 14px;
                            }
                            .voucher-logo-box {
                                width: 60px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

.default-voucher-wrapper {
    .reward-card-box {
        padding: 20px 25px;
        // box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
        background: #fff;
        margin-bottom: 20px;
        display: inline-flex;
        align-items: center;
        // min-height: 150px;
        height: 70px;
        // min-width: 350px;

        .logo-box {
            padding: 30px 15px 30px 0;
            margin-right: 15px;
            position: relative;
            width: 130px;

            &::after {
                // content: '';
                // width: 1px;
                // height: 100%;
                // right: 0;
                // position: absolute;
                // top: 0;
                // background-color: $msv-black;
            }
        }

        .card-detail {
            text-align: center;
            width: 100%;
        }

        h3 {
            font-family: $msv-bold-font-family;
            margin-bottom: 15px;
            font-size: $msv-font-size-l;
        }

        p {
            font-size: $msv-font-size-s;
            // margin-bottom: 15px;
            font-family: $msv-secondary-font-family;

            span {
                display: block;
            }

            &:last-child {
                margin-bottom: 0;
                font-size: $msv-font-size-xs;
            }
        }

        @media (min-width: $msv-container-max-width-ml) and (max-width: $msv-container-max-width-l) {
            h3 {
                margin-bottom: 12px;
                font-size: $msv-font-size-l;
            }

            p {
                font-size: $msv-font-size-s;
                margin-bottom: 12px;
            }
        }
}
}

.two-plus-wrapper{
    display: flex;
    flex-wrap: wrap;
    .reward-card-wrapper {
        padding-right: 20px;
        flex-basis: calc(33.333% - 10px);
        &:nth-child(3n + 3) {
            padding-right: 0;
        }
        .reward-card-box {
            min-width: 335px;
        }
    }
}

.custom-account-profile {
    margin-bottom: 40px;
    
    h2 {
        text-align: center;
        font-family: $msv-secondary-font-family;
        font-weight: $msv-font-weight-normal;
        font-size: $msv-font-size-l;
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    .welcome {
        font-weight: bold;
    }
}

.custom-image-container {
    > .row {
        > div[class^='col'] {
            padding: 0 5px;

            &:first-child {
                padding: 0 10px 0 0;
            }

            &:last-child {
                padding: 0 0 0 10px;
            }
        }
    }
}

.forward-button {
    background-color: transparent;
    border: none;
    color: $msv-gray-900;
    position: relative;
    font-weight: bold;
    align-items: center;

    &::after {
        content: $msv-arrow-right;
        font-family: $msv-icon-font-family;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
        line-height: 1;
        position: absolute;
        right: -25px;
        // top: 4px;
    }
}

button {
    &.back-button {
        background-color: transparent;
        border: none;
        color: $msv-gray-400;

        &::before {
            content: $msv-BackArrow;
            font-family: $msv-icon-font-family;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            text-rendering: auto;
            line-height: 1;
        }
    }

    &.forward-button {
        background-color: transparent;
        border: none;
        color: $msv-gray-900;

        &::after {
            content: $msv-arrow-right;
            font-family: $msv-icon-font-family;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            text-rendering: auto;
            line-height: 1;
        }
    }
}

.Account-information-wrapper {
    max-width: 768px;
    margin: 0 auto 45px;

    .heading-btn-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;

        .btn-child-box {
            font-family: $msv-secondary-font-family;
            color: $msv-gray-900;
            font-size: $msv-font-size-s;
            cursor: pointer;
            &.info-heading{
                cursor: default;
            }

            span {
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                color: $msv-gray-900;
                font-family: $msv-secondary-font-family;

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 15px;
        font-size: $msv-font-size-s;
        text-transform: initial;

        .MuiFormLabel-root {
            &.MuiInputLabel-outlined {
                transform: translate(14px, 12px) scale(1);
                font-size: $msv-font-size-s;
                color: $msv-gray-1200;
                margin-bottom: 0;

                &.MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.75);
                    color: $msv-gray-1200;
                }
            }

            &.Mui-focused {
                color: $msv-gray-900;
            }
        }

        .MuiOutlinedInput-root {
            border-radius: 0;

            .MuiOutlinedInput-input {
                padding: 9.5px 14px;
                font-size: $msv-font-size-s;
                color: $msv-gray-1200;
                font-family: $msv-primary-font-family;
            }

            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border-color: $msv-gray-900;
                }
            }
            &.Mui-error{
                .MuiOutlinedInput-notchedOutline {
                    border-color: #f44336;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: $msv-gray-900;
            }


        }
    }

    > .row {
        > div[class^='col'] {
            padding-left: 15px;
            padding-right: 60px;

            @media (max-width: $msv-breakpoint-l) {
                padding-right: 30px;
            }
            @media (max-width: $msv-breakpoint-m) {
                padding-right: 15px;
            }
        }
    }

    >.account-heading{
        h6 {
            font-size: $msv-font-size-m;
            font-family: $msv-primary-font-family;
            color: $msv-gray-900;
            margin-bottom: 15px;
        }
        .heading-btn-box {
            justify-content: flex-end;
            @media (max-width: $msv-breakpoint-sm) {
                justify-content: flex-start;
            }
            a{
                &:hover{
                    text-decoration: none;
                }
            }
        }

        >.row {
            >div[class^='col'] {
                padding-left: 15px;
                padding-right: 60px;
    
                @media (max-width: $msv-breakpoint-l) {
                    padding-right: 30px;
                }
                @media (max-width: $msv-breakpoint-m) {
                    padding-right: 15px;
                }
            }
        }
    }

    .msc-address-form__button-save,
    .msc-address-form__button-cancel {
        min-width: 147px;
        @media (max-width:$msv-container-max-width-ml) {
            min-width: 139px;
        }
        @media (max-width:$msv-container-max-width-m) {
            min-width: 110px;
        }
        @media (max-width:$msv-container-max-width-m) {
            min-width: 133px;
        }
        @media (max-width:$msv-container-max-width-s) {
            min-width: 106px;
        }
        @media (max-width:$msv-breakpoint-m) {
            width: calc(100% / 2 - 7.5px);
        }
    }

    .msc-address-form__button-save {
        margin-right: 15px;
        .custom-loader-alignment{
            margin-bottom: 2px;
            margin-left: 10px;
        }
    }
}

.modal-body {
    .memberID {
        .ms-account-profile__section {
            padding: 35px;
            width: 350px;

            .ms-account-profile__section-links {
                display: none;
            }
        }

        > img {
            margin: auto;
        }
    }

    .points-history {
        padding: 35px;
        width: 350px;

        .card {
            padding: 15px;
        }
    }
}

.profile-sub-container {
    background-color: $msv-gray-50;
    padding: 0 0 100px;
}

.sub-porfile-wrap {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;

    ul.nav {
        border-bottom: 2px solid #1d1d1d;
    }

    .ms-content-block {
        &.Custom__Page_heading {
            text-align: left;
        }
        h2 {
            font-family: $msv-bold-font-family;
            text-transform: uppercase;
            font-size: $msv-heading-text-size;
        }
    }

    .recipts-detail {
        min-height: 50px;
        max-height: 400px;
        min-height: 50px;
        overflow: auto;
        padding-right: 5px;
        scrollbar-color: #323130 #dfdfdf;
        scrollbar-width: thin;
        margin-right: 1px;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-button {
            width: 5px;
        }

        &::-webkit-scrollbar-button {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #eee;
            border: thin solid #dfdfdf;
            box-shadow: 0px 0px 3px #dfdfdf inset;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #323130;
            border: thin solid #323130;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #7d7d7d;
        }

        p {
            font-size: $msv-font-size-s;
            margin: 15px 0 5px;
            text-transform: uppercase;
            font-family: $msv-primary-font-family;
        }
    }

    @media (max-width: $msv-container-max-width-ml) {
        padding: 0;
        max-height: initial;
    }

    .sub-profile-head {
        margin-bottom: 50px;
        text-align: left;

        h2 {
            font-family: $msv-bold-font-family;
            text-transform: uppercase;
            font-size: $msv-font-size-xl;
            margin-bottom: 15px;
        }

        h3 {
            font-family: $msv-secondary-font-family;
            text-transform: uppercase;
            font-size: $msv-font-size-m;
            margin-bottom: 15px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: $msv-font-size-s;
            }
        }
        span{
            display: block;
            line-height: $msv-line-height-l;
            span{
                display: inline-block;
            }
        }
    }

    ul.profile-cat-list {
        text-align: left;
        margin-top: 40px;
        max-height: 400px;
        min-height: 50px;
        overflow: auto;
        padding-right: 10px;
        scrollbar-color: #323130 #dfdfdf;
        scrollbar-width: thin;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-button {
            width: 5px;
        }

        &::-webkit-scrollbar-button {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #eee;
            border: thin solid #dfdfdf;
            box-shadow: 0px 0px 3px #dfdfdf inset;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #323130;
            border: thin solid #323130;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #7d7d7d;
        }

        li {
            position: relative;
            border-bottom: 1px solid $msv-gray-900;
            padding: 10px 0;
            .bonus-list-wrapper{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .pointsDetail {
                font-family: $msv-bold-font-family;
                .positivePoints{
                    > span{
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        top: -1px;
                    }
                }
            }

            .storeName,
            .dateTime-box {
                margin-bottom: 5px;
            }

            a {
                font-family: $msv-bold-font-family;
            }
        }
    }

    scrollbar-color: #323130 #dfdfdf;
    scrollbar-width: thin;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        width: 5px;
    }

    &::-webkit-scrollbar-button {
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #eee;
        border: thin solid #dfdfdf;
        box-shadow: 0px 0px 3px #dfdfdf inset;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #323130;
        border: thin solid #323130;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #7d7d7d;
    }

    ul.nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        border-bottom: 2px solid $msv-gray-900;

        li.nav-item {
            a {
                padding: 5px 0;

                &:hover {
                    cursor: pointer;
                }

                &:focus {
                    font-family: $msv-bold-font-family;
                }
            }

            &.active {
                text-decoration: none;
                a {
                    font-family: $msv-bold-font-family;
                    color:#fff;
                }
            }
        }
    }

    .recipts-detail {
        .recipt-list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0;
            border-bottom: 2px solid $msv-gray-900;
            &:hover{
                text-decoration: none;
            }
            &-item-name{
                text-align: left;
                h4 {
                    font-family: $msv-primary-font-family;
                    font-size: $msv-font-size-s;
                    margin-bottom: 0;
                    line-height: $msv-line-height-l;
                }
                p{
                    margin: 0;
                    text-align: left;
                    line-height: 21px;
                }
            }
            &-item-detail-box{
                display: flex;
                align-items: center;
                &-item-detail{
                    text-align: left;
                    min-width: 85px;
                    p{
                        margin: 0;
                        line-height: 21px;
                    }
                }
                &-item-icon{
                    padding: 0 15px;
                    @media screen and (max-width: $msv-breakpoint-m) {
                        padding: 0 10px;
                    }
                    .arrow {
                        display: block;
                        cursor: pointer;
                        background: transparent;
                        opacity: 0.75;
                        z-index: 1;
                        width: 10px;
                        height: 10px;
                        border-left: none;
                        border-top: none;
                        border-right: 2px #000 solid;
                        border-bottom: 2px #000 solid;
                        transform: rotate(-45deg);
                        @media screen and (max-width: $msv-breakpoint-m) {
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }
            
        }
    }
}

.Member-id-wrapper {
    max-width: 300px;
    margin: 0 auto;

    .member-box {
        h3 {
            font-family: $msv-secondary-font-family;
            font-size: $msv-font-size-s;
            text-transform: capitalize;
        }

        h4 {
            font-family: $msv-secondary-font-family;
            font-size: $msv-font-size-l;
            text-transform: uppercase;
            margin-bottom: 15px;
        }
    }

    .qr-code-wrapper {
        background: $msv-white;
        padding: 20px 20px 30px 20px;
        margin-bottom: 15px;
        // box-shadow: -1px 4px 2px #e1e3e6;
        border: 1px solid $msv-gray-50;

        .qr-logo-box {
            width: 90px;
            margin: 0 auto;
            padding: 5px 0 25px;
        }

        .qr-box {
            text-align: center;
        }

        .qr-code {
            text-align: center;

            h6 {
                margin: 15px 0;
                font-size: 20px;
                letter-spacing: 0px;
                color: $msv-gray-500;
                font-family: $msv-secondary-font-family;
            }
        }
    }

    .point-btn {
        // background: $msv-white;
        // padding: 15px;
        // position: inherit;
        // text-align: center;
        // border: 1px solid $msv-gray-50;
        // width: 100%;
        // align-items: center;
        @include secondary-button-dark();
        width: 100%;    
        margin-bottom: 30px;

        button {
            font-family: $msv-bold-font-family;
            font-size: $msv-font-size-s;
        }
        
        a:active {
            color: white;
        }

        a:hover {
            text-decoration: none;
            color: var(--msv-account-profile-font-color);
        }
    }
}