@import "../common/00-settings/colors.scss";
@import "../common/00-settings/icons.scss";
@import "../common/00-settings/font.scss";
@import "../common/02-generic/button.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/01-tools/layout.scss";
@import "../common/02-generic/image.scss";
// @import "../common/02-generic/reset.scss";
@import "../common/01-tools/mixins.scss";

// font
$product-comparison-preview-main-container-header-icon-font-size: 25px;

:root {
    --msv-product-comparison-preview-background-color: #{$msv-white};
    --msv-product-comparison-preview-main-container-header-icon-color: #{$msv-gray-20};
    --msv-product-comparison-preview-main-container-header-icon-font-size: #{$product-comparison-preview-main-container-header-icon-font-size};
    --msv-product-comparison-preview-main-container-header-collapse-color: var(--msv-font-primary-color);
    --msv-product-comparison-preview-main-container-header-collapse-size: #{$product-comparison-preview-main-container-header-icon-font-size};
}

.ms-product-comparison-preview {
    @include font-body-regular-m();
    position: fixed;
    bottom: 0;
    top: auto;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    max-width: 70%;
    z-index: 1000000;
    box-shadow: $msv-depth24;
    border-radius: 5px 5px 0 0;
    background-color: var(--msv-product-comparison-preview-background-color);
    width: 100%;
    white-space: normal;

    @media (max-width: $msv-breakpoint-m) {
        max-width: unset;
    }

    &__container {
        width: 100%;

        &__main-container {
            padding-bottom: 72px;

            @media (max-width: $msv-breakpoint-m) {
                padding-bottom: 112px;
            }

            &__header__section {
                overflow: auto;
                transition: max-height 0.2s ease-out;
            }

            &__header_button {
                display: flex;
                height: 71px;
                left: 0;
                padding: 10px;
                position: fixed;
                width: 100%;
                padding-bottom: 0;
                padding-top: 0;
            }

            &__header {
                position: fixed;
                width: 100%;
                display: flex;
                justify-content: space-between;
                border: none;
                border-bottom: 0.5px solid $msv-gray-50;
                left: 0;
                padding: 10px;
                height: 71px;

                &__label {
                    display: flex;
                    align-items: center;

                    &__icon {
                        height: 25px;
                        width: 25px;
                        font-size: var(--msv-product-comparison-preview-main-container-header-icon-font-size);
                        margin: 10px;
                        color: var(--msv-product-comparison-preview-main-container-header-icon-color);

                        @include add-icon($msv-swap-box-line);

                        @media (max-width: $msv-breakpoint-m) {
                            display: none;
                        }
                    }

                    &__main-text {
                        @include font-heading-h3-l();

                        @media (max-width: $msv-breakpoint-m) {
                            font-size: 18px;
                        }
                    }

                    &__sub-text {
                        @include font-body-regular-s();
                        line-height: 20px;
                        margin: 0 10px;
                        margin-top: 6px;

                        @media (max-width: $msv-breakpoint-m) {
                            @include font-body-regular-xs();
                        }
                    }

                    margin: 0 10px;
                }

                &__go-to-comparison {
                    @include secondary-button();
                    margin-right: auto;
                }

                &__collapse {
                    color: var(--msv-product-comparison-preview-main-container-header-collapse-color);
                    background: transparent;
                    cursor: pointer;
                    font-size: var(--msv-product-comparison-preview-main-container-header-collapse-size);

                    &__expanded {
                        @include add-icon($msv-ChevronDown, after);

                        &::after {
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-left: auto;
                            padding: 1px 6px;
                        }
                    }

                    &__collapsed {
                        @include add-icon($msv-ChevronUp, after);

                        &::after {
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-left: auto;
                            padding: 1px 6px;
                        }
                    }
                }
            }

            &__table {
                border-spacing: 0;
                padding-top: 62px;
                margin-right: auto;
                height: 300px;
                padding-left: 36px;
                padding-right: 36px;
                padding-bottom: 20px;

                @media (max-width: $msv-breakpoint-m) {
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 72px;
                    height: 260px;
                    padding-bottom: unset;
                }

                &__product-row {
                    .placeholder-preview {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10px;
                        border: 0.5px solid $msv-gray-50;
                        height: 140px;
                        width: 140px;
                        background: $msv-white-20;
                    }

                    &__product-name {
                        @include font-body-regular-m();
                        text-align: center;
                        margin: auto;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        -webkit-box-orient: vertical;

                        @media (max-width: $msv-breakpoint-m) {
                            @include font-body-regular-xs();
                        }
                    }

                    &__product-image-container {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10px;
                        width: fit-content;
                        border: 1px solid $msv-gray-300;

                        @include image(140px);
                    }

                    &__remove {
                        @include vfi();
                        @include add-icon($msv-Cancel);

                        background-color: transparent;
                        border: 1px solid transparent;
                        color: var(--msv-font-primary-color);
                        position: absolute;
                        right: 0;
                        cursor: pointer;
                        margin-right: 16px;
                        margin-top: 11px;
                        z-index: 10;
                        font-size: 16px;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                tr {
                    td {
                        vertical-align: top;
                        text-align: left;
                        border-collapse: collapse;
                        position: sticky;
                        min-width: 170px;
                        max-width: 170px;
                        width: 170px;
                    }
                }

                tbody {
                    tr:first-child td {
                        padding-top: 20px;

                        @media (max-width: $msv-breakpoint-m) {
                            padding-top: unset;
                        }
                    }
                }
            }

            &__footer {
                position: fixed;
                width: 100%;
                box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.04);
                height: 72px;
                bottom: 0;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                place-content: flex-end;

                @media (max-width: $msv-breakpoint-m) {
                    display: flex;
                    flex-flow: column;
                    height: 112px;
                    padding: 0 20px;
                }

                &__clear-all {
                    @include secondary-button();
                    margin: 12px;
                    cursor: pointer;

                    @media (max-width: $msv-breakpoint-m) {
                        margin: 6px;
                        width: 100%;
                    }
                }

                &__go-to-comparison {
                    @include primary-button();
                    margin: 12px;
                    justify-content: center;
                    cursor: pointer;

                    @media (max-width: $msv-breakpoint-m) {
                        margin: 6px;
                        width: 100%;
                    }
                }
            }

            &__collapsed {
                max-height: 60px;
                overflow: hidden;
                padding-bottom: 0;
                margin-bottom: 10px;

                .ms-product-comparison-preview__container__main-container__footer {
                    display: none;
                    position: unset;
                }
            }
        }
    }
}
