@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/00-settings/icons.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/02-generic/image.scss";
@import "../common/02-generic/button.scss";

/*$navigation-parent-menu-margin-left: 20px;
$navigation-parent-menu-margin-right: 20px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-menu-item-line-height: 28px;
$navigation-menu-item-level2-line-height: 24px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-padding: 40px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 31px;
$navigation-ms-nav-area-width: 63%;
$navigation-menu-list-min-width: 180px;
$navigation-ms-nav-list-item-link-margin-bottom: 20px;

$navigation-ms-nav-list-mobile-container-padding-left: 16px;
$navigation-ms-nav-list-mobile-container-padding-right: 34px;
$navigation-ms-nav-list-button-mobile-padding-left: 0;
$navigation-ms-nav-list-button-mobile-padding-right: 0;
$navigation-ms-nav-list-button-before-mobile-font-size: 21px;
$navigation-ms-nav-list-item-link-mobile-margin-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding: 16px;
$navigation-ms-nav-list-feature-mobile-padding-bottom: 20px;
$navigation-ms-nav-child-mobile-padding-top: 12px;

$navigation-ms-nav-list-tablet-margin-left: 0;
$navigation-ms-nav-list-item-tablet-margin-left: 0;
$navigation-ms-nav-list-level3-tablet-margin-left: 20px;
$navigation-ms-nav-ms-nav-list-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-link-level2-tablet-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level3-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-tablet-first-child-margin-left: 0;
$navigation-ms-nav-close-button-tablet-padding-right: 16px;
$navigation-ms-nav-close-button-tablet-padding-top: 10px;
$navigation-ms-nav-list-item-link-button-tablet-margin-bottom: 28px;
$navigation-ms-nav-list-item-nav-feature-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-drawer-button-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-tablet-padding-left: 40px;
$navigation-ms-nav-list-item-link-tablet-padding-zero: 0;
$navigation-ms-nav-list-item-button-first-child-tablet-margin-left: 0;
$navigation-ms-nav-drawer-open-close-tablet-padding: 13px;

$navigation-ms-nav-list-desktop-margin-left: 0;
$navigation-ms-nav-list-item-desktop-margin-left: 0;
$navigation-ms-nav-list-level2-desktop-margin-left: 20px;
$navigation-ms-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-link-desktop-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level2-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-button-link-desktop-margin-bottom: 40px;
$navigation-ms-nav-list-item-first-child-desktop-margin-left: 0;
$navigation-ms-nav-default-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-right: 60px;
$navigation-ms-nav-list-item-levle2-desktop-padding-right: 20px;
$navigation-ms-nav-list-item-drawer-button-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-desktop-padding-left: 40px;
$navigation-ms-nav-list-item-link-desktop-padding-left: 0;
$navigation-ms-nav-list-item-link-desktop-padding-right: 0;
$navigation-ms-nav-list-item-button-first-child-desktop-margin-left: 0;
$navigation-ms-nav-drawer-open-desktop-padding-right: 13px;
$navigation-ms-nav-drawer-close-desktop-padding-right: 13px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-right: 20px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom: 25px;

$msv-width-onequarter: 25%;

//style presets
:root {
    --msv-nav-bg: var(--msv-accent-brand-color);
    --msv-nav-border: transparent;
    --msv-nav-dropdown-border: transparent;
    --msv-nav-carot-color: var(--msv-font-secondary-color);
    --msv-nav-font-color: var(--msv-font-secondary-color);
    --msv-nav-font-size: var(--msv-body-font-size-m);
    --msv-nav-close-icon-font-size: #{$msv-icon-size-xs};
}*/

//.nav-menu-container,  .msc-modal__dialog.ms-header__mobile-hamburger * { //TODO: Remove before commit
//    box-shadow: 0 0 2px red inset;
//}

.ms-nav {
    display: flex !important;
    background: #FFF;
    height: 700px;
    max-height: calc(100vh - 33px - 100px); //NOTE: streamer + buttom space.
    overflow: scroll;
    padding: 28px 38px;
    line-height: 1; // Remove default 1.5
    box-shadow: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
    animation: fadeIn 0.25s ease-in-out forwards;

    >.ms-nav__list {
        position: relative;
        display: flex;
        width: 100%;

        >.ms-nav__list__item {
            >.ms-nav__deafult {
                position: absolute;
                top: 52px;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: scroll;
                background: #FFF;
                animation: fadeIn 0.25s ease-in-out forwards;

                >.ms-nav__list {
                    display: grid;
                    grid-template-rows: repeat(12, auto);
                    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
                    grid-auto-flow: column;
                    margin: 0;
                    padding: 0;

                    >.ms-nav__list__item {
                        padding: 0;
                        list-style-type: none;
                        margin: 0 0 12px 0;

                        >.ms-nav__list__item__link {
                            color: #515151;
                            font-size: 14px;
                            font-weight: 400;
                            text-decoration: none;
                            text-transform: uppercase;
                            transition: color 0.1s ease-in-out;

                            &:hover,
                            &:active {
                                color: #000;
                            }
                        }

                        >.ms-nav__deafult {
                            display: none;
                        }
                    }

                    &__promotion {
                        margin: 0 0 (60px - 12px) 0;

                        >.ms-nav__list__item>.ms-nav__list__item__link {
                            font-size: 16px;
                            font-weight: 500;
                            font-family: 'Conv_EuropaBold';
                        }
                    }
                }
            }
        }
    }

    .ms-nav__list__item__button {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.1s ease-in-out;
        margin: 0 22px 0 0;
        padding: 0;
        border: 0;
        background: transparent;

        &[aria-expanded="true"],
        &:hover {
            color: #000;
            border-bottom: 1px solid black;
            padding-bottom: 6px;
        }
    }

    .ms-nav-close-button {
        margin-left: auto;

        .nav-menu-close {
            line-height: 1;
            margin: -8px;
            padding: 8px;
            border: 0;
            border-radius: 0;
            background: transparent;

            &::before {
                @include msv-icon();
                content: $msv-Cancel;
            }
        }
    }
}

.msc-modal__dialog.ms-header__mobile-hamburger {
    width: inherit;
    height: 100%;
    box-shadow: none;
    background: #FFF;

    .msc-modal__content,
    .msc-modal__body {
        width: inherit;
        height: inherit;
        max-height: none;
        margin: 0;
        padding: 0 !important; // Remove default padding from modal.scss
        border: 0;
        box-shadow: none;
    }

    .msc-modal__body {
        display: flex;
        flex-direction: column;
    }

    .msc-modal__header {
        display: flex;
        align-items: center;
        height: 78px;
        margin: 0;
        padding: 0;
    }

    .nav-menu-container {
        position: relative;
        flex-grow: 1;
    }

    .ms-header__mobile-hamburger-menu-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 72px 14px 30px 14px;

        a {
            color: #515151;
            font-size: 14px;
            font-weight: 400;
            text-decoration: none;
            transition: color 0.1s ease-in-out;
            margin: 12px 0 0 0;
            background: transparent;

            &:hover {
                color: #000;
            }
        }
    }

    .ms-nav {
        position: relative;
        height: 100%;
        max-height: none;
        padding: 0 14px 0 14px;
        box-shadow: none;
        animation: none;

        >.ms-nav__list {
            display: block;

            >.ms-nav__list__item {
                overflow: hidden;

                &:not(:last-child) {
                    margin: 0 0 20px 0;
                }

                >.ms-nav__list__item__button {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 0;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    &::after {
                        content: "\ea6c"; //REF: https://remixicon.com/icon/arrow-left-line
                        font-family: "Remix Icon";
                    }
                }

                >.ms-nav__deafult {
                    top: 0;
                    left: 100%;
                    background: #FFF;
                    animation: slideInRight 0.25s ease-in-out forwards;

                    >.ms-nav__list {
                        display: block;

                        &__promotion {
                            margin: 0 0 30px 0;

                            >.ms-nav__list__item>.ms-nav__list__item__link {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        &__list__mobile__container {
            display: flex;
            align-items: center;
            position: fixed;
            top: 0;
            width: calc(100% - (37px + (2 * 20px)) - 14px);
            height: 78px;
            animation: fadeIn 0.25s ease-in-out forwards;

            .ms-nav__list__mobile__container__button {
                width: (20px + (8px * 2));
                margin: -8px 8px -8px -8px;
                padding: 8px;
                border: 0;
                background: transparent;

                &::before {
                    content: "\ea60"; //REF: https://remixicon.com/icon/arrow-left-line
                    font-family: "Remix Icon";
                }
            }

            .ms-nav__list__mobile__container__span {
                text-align: center;
                display: block;
                flex-grow: 1;
                margin-left: (37px + (2 * 20px) - (20px + (8px * 2)) - 14px);
            }
        }
    }

    .msc-modal__close-button {
        right: 28px;
        margin: -8px;
        padding: 8px;

        &:before {
            content: "\f4c8"; //REF: https://remixicon.com/icon/close-large-line
            font-family: "Remix Icon";
        }
    }

    .msc-btn.ms-header__wishlist-mobile:before {
        content: "";
        font-family: 'icomoon';
        font-weight: 900;
        margin-right: 14px;
    }

    .msc-btn.ms-header__signin-button:before {
        content: "";
        font-family: 'icomoon';
        font-weight: 900;
        margin-right: 14px;
    }
}

@keyframes slideInRight {
    0% {
        left: 100%;
    }

    100% {
        left: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}