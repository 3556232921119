@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/02-generic/image.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/00-settings/icons.scss";

$image-list-title-font-weight: 500 !important;
$image-list-title-link-margin: 3px;
$image-list-title-text-margin: 3px;
$image-width-medium-layout: 65px;
$image-height-medium-layout: 65px;
$image-width-large-layout: 305px;
$image-height-large-layout: 172px;

.ms-image-list {
    max-width: 70%;

    @media (max-width: $msv-breakpoint-m) {
        width: 100%;
        max-width: 100%;
    }

    margin: auto;

    &__title {
        @include font-heading-h2-l();
        padding-bottom: 15px;
    }

    &__link {
        margin: $image-list-title-link-margin;
        width: 40px;
        margin-right: 12px;
    }

    &__text {
        @include font-body-regular-s();
        margin: $image-list-title-text-margin;

        a:hover {
            text-decoration: none;
            color: var(--msv-font-primary-color);
        }

        h2 {
            @include conv-europa-bold;
            font-size: 16px;
            letter-spacing: 0.02em;
            font-weight: $image-list-title-font-weight;
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            text-align: center;
        }
    }

    &__list {
        @include font-body-regular-m();
        display: flex;
        flex-wrap: wrap;

        &-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            max-width: 500px;

            @media (max-width: $msv-breakpoint-m - 1) {
                flex-direction: column !important;
                align-items: center !important;
                margin-top: 12px;
            }
        }
    }

    &.displaystyle {
        &__vertical {
            .ms-image-list__list {
                display: grid;
                justify-content: flex-start;
            }
        }

        &__horizontal {
            .ms-image-list__list {
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 12px;

                @media (max-width: $msv-breakpoint-m - 1) {

                    flex-direction: column;
                    justify-content: center;

                    &-item {
                        margin-left: 10px;
                    }
                }
                
                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                        flex-direction: column;
                        justify-content: center;

                        &-item {
                            margin-left: 200px;
                        }
                }
            }
        }
    }

    &.imageplacement {
        &__top {
            .ms-image-list__list-item {
                flex-direction: column;
            }
        }

        &__bottom {
            .ms-image-list__list-item {
                flex-direction: column-reverse;
            }
        }

        &__left {
            .ms-image-list__list-item {
                flex-direction: row;
            }
        }

        &__right {
            .ms-image-list__list-item {
                flex-direction: row-reverse;
                text-align: right;
            }
        }
    }

    &.contentalignment {
        &__left {
            text-align: left;
            flex-direction: row;
            justify-content: flex-start;
            float: left;
        }

        &__center {
            text-align: center;

            .ms-image-list__list {
                justify-content: center;
            }
        }

        &__right {
            text-align: right;
            flex-direction: row;
            justify-content: flex-end;
            float: right;

            @media (max-width: $msv-breakpoint-m) {
                .ms-image-list__list {
                    display: inline-block;
                    justify-content: flex-end;
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                .ms-image-list__list {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }

    &[data-m-layout="mediumImageSettings"] {
        .msc-image-container {
            @include image($image-width-medium-layout, $image-height-medium-layout);
        }
    }

    &[data-m-layout="largeImageSettings"] {
        .msc-image-container {
            @include image($image-width-large-layout, $image-height-large-layout);
        }
    }
}

// -----UNIQUE CLASSES-----

.image-list__country-picker {
    &.ms-image-list.displaystyle__horizontal.imageplacement__left.contentalignment__center {

        .ms-image-list__list {
            gap: 36px;

            @media (max-width: $msv-breakpoint-m - 1) {
                gap: 12px;
            }
    
            &-item {
                flex-direction: row !important;
                justify-content: center !important;
                margin-left: 0 !important;
            }
        }
    } 
}