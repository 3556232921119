@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/00-settings/icons.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/01-tools/layout.scss";
@import "../common/01-tools/visually-hidden.scss";
// @import "../common/02-generic/reset.scss";

//spacing
$header-container-max-width: 1440px;
$header-container-spacing-to-bottom-tablet: 10px;
$signin-info-spacing-left: 8px;
$heading-padding-top: 20px;
$heading-padding-bottom: 22px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 30px;
$header-hamburger-icon-width: 21px;
$account-link-height: 35px;
$account-links-max-width: 239px;
$popover-menu-item-height: 48px;
$header-acc-info-desktop-margin-right: 26px;
$header-nav-icon-margin-right-desktop: 10px;
$header-logo-margin-right-desktop: 32px;
$header-logo-padding-mobile: 7px 0;
$header-logo-min-width: 116px;
$header-logo-min-height: 20px;
$header-cart-count-margin-bottom: 20px;
$header-cart-count-border-radius: 100px;
$header-cart-count-padding: 2px 6px 0 6px;
$header-search-margin-right-desktop: 40px;
$header-locator-margin-right-desktop: 26px;
$header-search-margin-right-tablet: 0px;
$header-container-topbar-account-info-margin-left: 0;
$header-container-topbar-account-info-signin-button-padding: 0;
$msv-profile-button-text-margin-left: 10.33px;
$msv-pop-over-box-shadow-color: rgba(0, 0, 0, 0.1);
$msv-account-pop-over-box-shadow-color: rgba(0, 0, 0, 0.13);

$header-modal-mobile-hamburger-content-padding: 0;
$header-modal-mobile-hamburger-body-padding: 0;
$header-modal-mobile-hamburger-body-button-padding: 12px 8px;
$header-modal-mobile-close-button-padding-right: 12px;
$header-modal-mobile-close-button-padding-top: 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-accent-brand-color);
    --msv-header-font-color: var(--msv-font-secondary-color);
    --msv-header-icon-font-size: #{$msv-icon-size-xs};
}

.ms-header .msc-btn {
    background-color: var(--msv-header-bg);
}

/*.msc-modal .ms-header__mobile-hamburger .msc-btn {
    display: block;
    background-color: var(--msv-header-bg);
}*/

.lock-opaque {
    top: 0;
    position: fixed;
    z-index: 1010;
    width: 100%;
    box-shadow: $msv-depth8;

    .ms-header__collapsible-hamburger.show {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        min-height: auto !important;
    }
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);

    margin-left: auto;
    margin-right: auto;

    &__container {

        .ms-header__collapsible-hamburger.collapse {
            &:not(.show) {
                display: none;
            }
        }

        margin: 0 auto;

        .ms-header__preferred-store-btn,
        .ms-search__icon,
        .ms-header__signin-button,
        .msc-wishlist-icon,
        .msc-cart-icon,
        .ms-header__profile-button {
            @include font-body-regular-m();

            color: var(--msv-header-font-color);
            border: 1px solid transparent;
            height: 48px;
            width: $header-icon-width;

            align-items: center;
            display: flex;
            justify-content: flex-end;

            @include vfi();

            &:hover {
                // border: none;
                text-decoration: none;
            }
        }

        .ms-search__icon:before,
        .ms-search__form-submitSearch:before {
            font-weight: 900;
            font-size: 17px;
        }

        .msc-cart-icon[data-quantity]:not([data-quantity="0"]) {
            &:before {
                content: "\E900";
                font-family: 'icomoon';
            }

            .msc-cart-icon__count {
                top: 17px;
                right: 3px;
                color: #FFF;
            }
        }

        .msc-cart-icon[data-quantity="0"] {

            .msc-cart-icon__count {
                display: none;
            }
        }

        .msc-wishlist-icon {
            @include font-body-regular-m();

            background-color: var(--msv-header-bg);
            color: var(--msv-header-font-color);
            align-items: center;
            // display: flex;
            // height: 48px;
            white-space: nowrap;
            cursor: pointer;
            padding: $header-container-topbar-account-info-signin-button-padding;

            &::before {
                @include msv-icomoon-icon();
                content: $ico-Heart;
                color: $msv-gray-900;
                //font-size: 20px;

                // @include msv-icon();
                // content: $msv-Heart;
                // font-size: var(--msv-header-icon-font-size);
                // width: auto;
                // vertical-align: text-bottom;
                // text-align: center;
                // font-weight: normal;
                // color: var(--msv-header-font-color);
            }

            @media (max-width: $msv-breakpoint-m - 1) {
                display: none;
            }
        }

        .ms-header__switchcustomer-button-text {
            width: 100%;

            >div {
                &.ms-header__shoppingas-text {
                    display: none;
                }
            }
        }

        .ms-profile-button-text {
            display: inline-block;
            text-align: left;
        }

        .ms-profile-button-splitter {
            border-top: 1px solid $msv-secondary;
        }

        .ms-header__account-info {
            @include font-body-regular-m();
            justify-content: flex-end;
            height: $header-height;
            width: $header-icon-width;
            //margin-right: $header-acc-info-desktop-margin-right;
            display: flex;

            .ms-header__signin-button,
            .ms-header__profile-button {
                @include font-body-regular-m();

                background-color: var(--msv-header-bg);
                color: var(--msv-header-font-color);
                align-items: center;
                display: flex;
                height: 48px;
                white-space: nowrap;
                cursor: pointer;
                padding: $header-container-topbar-account-info-signin-button-padding;

                &::before {
                    @include msv-icomoon-icon();
                    content: $ico-Account;
                    color: $msv-gray-900;

                    // @include msv-icon();
                    // content: $msv-signin;
                    // font-size: var(--msv-header-icon-font-size);
                    // width: auto;
                    // vertical-align: text-bottom;
                    // text-align: center;
                    // font-weight: normal;
                    // color: var(--msv-header-font-color);
                }
            }

            .ms-header__profile-button {
                @include font-body-regular-m();

                background-color: var(--msv-header-bg);
                color: var(--msv-header-font-color);
                align-items: center;
                display: flex;
                height: 48px;
                white-space: nowrap;
                cursor: pointer;
                padding: $header-container-topbar-account-info-signin-button-padding;
                
                &::before {
                    @include msv-icomoon-icon();
                    content: $ico-Account-filled;
                    color: $msv-gray-900;
               }
            }

            &__account-link-container {
                height: $account-link-height;
            }

            a {
                @include font-body-regular-m();
                align-items: center;
                display: flex;
                height: $header-height;
                line-height: $msv-line-height-m;
                background-color: $msv-white;
                color: $msv-primary;
                white-space: nowrap;
                padding-left: 20px;
                padding-right: 20px;
            }

            .msc-popover {
                background-clip: padding-box;
                background-color: $msv-white;
                display: block;
                left: 0;
                max-width: $account-links-max-width;
                position: absolute;
                word-wrap: break-word;
                z-index: 1060;
                box-shadow: 0 0.6px 1.8px $msv-pop-over-box-shadow-color, 0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;

                .msc-popover-inner {

                    .ms-signin-info__account-link-button:hover,
                    .ms-header__signout-button:hover {
                        background-color: $msv-white-20;
                    }
                }

                .msc-btn {
                    background-color: $msv-white;
                    color: $msv-primary;
                    font-weight: normal;
                    height: $popover-menu-item-height;
                }

                .msc-arrow {
                    display: none;
                }
            }

            .ms-profile-button-text,
            .ms-header__signin-button-text {
                margin-left: $msv-profile-button-text-margin-left;
                vertical-align: top;
                display: none;
            }
        }

        .ms-header__topbar {
            margin: 0 auto;
            //max-width: $header-container-max-width;
            align-items: center;
            justify-content: space-between;
            display: flex;
            flex-wrap: nowrap;
            height: $header-mobile-height;

            padding-left: 36px;
            padding-right: 36px;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: 0px 12px;
            }

            // @media screen and (min-width: $msv-breakpoint-l) {
            //     padding-left: $msv-layout-container-padding-l;
            //     padding-right: $msv-layout-container-padding-l;
            // }

            .mobile-navicon-container,
            .desktop-navicon-container {
                flex: 1;
            }

            .icon-wrapper {
                display: flex;
                flex: 1;
                justify-content: flex-end;
                align-items: center;

                /*@media screen and (max-width: $msv-breakpoint-m - 1) {
                    max-width: 70px;
                }

                @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                    max-width: 250px;
                }

                @media screen and (min-width: $msv-breakpoint-l) {
                    min-width: 450px;
                }*/
            }

            .nav-menu-container {
                display: flex;
                align-items: center;

                >.ms-nav__list .ms-nav__list__item__link {
                    color: #515151;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: color 0.1s ease-in-out;
                    border: 0;
                    margin-left: 12px;

                    &:hover {
                        color: #000;
                    }
                }
            }

            .ms-header__nav-icon {

                @media (min-width: $msv-breakpoint-l) {
                    display: block;
                }

                @include vfi();
                background: var(--msv-header-bg);
                display: flex;
                align-items: center;
                color: var(--msv-header-font-color);
                //min-width: 70px;
                border: 1px solid transparent;
                margin-right: $header-nav-icon-margin-right-desktop;
                padding: 0;
                cursor: pointer;
                padding: 0;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icomoon-icon();
                    content: $ico-Hamburger;
                    color: $msv-gray-900;

                    //@include msv-icon();
                    //content: $msv-GlobalNavButton;
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-hamburger-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                }

                @media (max-width: $msv-breakpoint-l - 1) {
                    @include vfi();
                    background: var(--msv-header-bg);
                    display: flex;
                    align-items: center;
                    color: var(--msv-header-font-color);
                    //min-width: 70px;
                    border: 1px solid transparent;
                    margin-right: $header-nav-icon-margin-right-desktop;
                    padding: 0;
                    cursor: pointer;
                    padding: 0;

                    &:hover {
                        text-decoration: none;
                    }

                    &::before {
                        @include msv-icomoon-icon();
                        content: $ico-Hamburger;
                        color: $msv-gray-900;

                        // @include msv-icon();
                        // content: $msv-GlobalNavButton;
                        // font-size: var(--msv-header-icon-font-size);
                        // width: $header-hamburger-icon-width;
                        // vertical-align: text-bottom;
                        // text-align: center;
                    }

                    height: 44px;
                    //width: $header-icon-width;
                    margin-right: 0;
                }
            }

            .ms-header__logo {
                display: block;
                margin-right: $header-logo-margin-right-desktop;

                a {
                    @include vfi();
                    display: block;
                }

                img {
                    width: auto;
                    height: 65px;
                    min-width: $header-logo-min-width;
                    min-height: $header-logo-min-height;
                }
            }

            .ms-header__mobile-logo {
                display: none;
            }

            .ms-header__preferred-store-container {
                .ms-header__preferred-store-btn {
                    border: 1px solid transparent;
                    display: flex;
                    height: $header-height;
                    line-height: $msv-line-height-m;
                    align-items: center;
                    padding: 0;
                    cursor: pointer;

                    @include vfi();
                    @include add-icon($msv-Shop-Address);

                    &::before {
                        font-size: var(--msv-header-icon-font-size);
                        display: inline-block;
                        width: auto;
                        text-align: center;
                    }

                    .ms-header__preferred-store-text {
                        @include font-body-regular-m();
                        color: var(--msv-header-font-color);
                        white-space: nowrap;
                    }
                }
            }

            .ms-header__wishlist-desktop {
                display: none;
                width: $header-icon-width;
                height: $header-height;
                align-items: center;

                // @include add-icon($ico-Heart, before, 600);

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                }


            }

            .msc-wishlist-icon__text {
                display: none;
            }

            .msc-cart-icon {
                display: flex;
                align-items: center;
                //justify-content: center;
                position: relative;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icomoon-icon();
                    content: $ico-Bag;
                    color: $msv-gray-900;
                    font-weight: 600;

                    // @include msv-icon();
                    // content: $msv-shopping-bag;
                    // font-size: var(--msv-header-icon-font-size);
                    // vertical-align: text-bottom;
                    // text-align: center;
                }

                &__count {

                    // @include font-content-s();
                    // color: var(--msv-header-font-color);
                    // margin-bottom: $header-cart-count-margin-bottom;
                    // padding: $header-cart-count-padding;
                    // background-color: $msv-blue;
                    // border-radius: $header-cart-count-border-radius;
                    // text-align: center;
                    // position: absolute;

                    //margin-left: 2px;
                    padding-left: 5px;
                    padding-right: 1px;
                    font-size: 10px;
                    font-weight: 200;
                    font-family: 'Conv_EuropaBold';
                    color: #333;
                    position: absolute;
                    top: 14px;

                    //left: calc(50% - 0.5px);
                    //transform: translateX(-50%);
                    @media (max-width: $msv-breakpoint-m) {
                        top: 14px;
                    }
                }
            }

            .ms-cart-icon-wrapper {
                border: 1px solid transparent;
                padding: 0;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;

                @include vfi();
            }

            .ms-header__account-info a:hover {
                text-decoration: none;
            }
        }

        .ms-header__collapsible-hamburger {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
            width: 100%;

            &.collapsing {
                display: none;
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }

    /* stylelint-disable no-descending-specificity -- Suppressed. */
    .hide-header-options {

        .ms-header__nav-icon,
        .msc-cart-icon,
        .msc-wishlist-icon {
            display: none;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-header__preferred-store-container {
            .ms-header__preferred-store-btn {
                cursor: pointer;

                &::before {
                    margin-right: $header-nav-icon-margin-right-desktop;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        .mobile-navicon-container {
            display: none;
        }

        .desktop-navicon-container {
            min-width: 250px;
        }

        //.ms-nav.desktop-vp {
        //    display: flex;
        //    position: relative;
        //}

        &__container {
            .ms-header__topbar {
                .ms-search {
                    margin-right: $header-search-margin-right-tablet;
                    height: $header-height;
                }

                .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                }

                .ms-header__preferred-store-container {
                    margin-right: 0;
                }

                .ms-header__preferred-store-btn {
                    margin-right: $header-search-margin-right-tablet;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }

                .ms-header__container .ms-header__topbar .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                    margin-top: 0;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-top: $heading-padding-top;
        padding-bottom: $heading-padding-bottom;

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);
            width: $header-icon-width;

            &::before {
                @include font-body-regular-m();
                @include msv-icomoon-icon();
                content: $ico-Account-filled;
                color: $msv-gray-900;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        &__divider {
            display: none;
        }

        .ms-search {
            border-bottom: 1.5px solid $msv-gray-50;
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 200px;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: none;
            }
        }

        &__preferred-store-container {
            margin-right: $header-locator-margin-right-desktop;
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        //.ms-nav.desktop-vp {
        //    display: flex;
        //    position: relative;
        //}

        .mobile-navicon-container {
            display: none;
        }

        .desktop-navicon-container {
            min-width: 450px;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-profile-button-text {
            display: none;
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            // &::before {
            //     @include font-body-regular-m();
            //     @include msv-icon();
            //     content: $msv-signin;
            //     color: var(--msv-header-font-color);
            &::before {
                @include msv-icomoon-icon();
                content: $ico-Account-filled;
                color: $msv-gray-900;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-top: $header-container-spacing-to-bottom-tablet;
        padding-bottom: $header-container-spacing-to-bottom-tablet;

        &__container {
            .ms-header__topbar {
                .ms-header__mobile-logo {
                    display: block;

                    a {
                        @include vfi();
                        padding: $header-logo-padding-mobile;
                    }
                }

                .ms-header__logo {
                    display: none;
                }

                .ms-header__preferred-store-container {
                    .ms-header__preferred-store-btn {
                        cursor: pointer;

                        @include vfi();

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                            vertical-align: text-bottom;
                            text-align: center;
                        }

                        .ms-header__preferred-store-text {
                            display: none;
                        }
                    }
                }

                .ms-header__account-info {
                    margin-left: $header-container-topbar-account-info-margin-left;

                    .ms-header__signin-button {
                        padding: $header-container-topbar-account-info-signin-button-padding;

                        .ms-header__signin-button-text {
                            display: none;
                        }
                    }
                }

                .ms-site-picker.mobile-view {
                    .drawer__buttontext.__start {
                        display: none;
                    }

                    .collapse.show,
                    .collapsing {
                        position: absolute;
                        right: 0;
                    }

                    button.drawer__button.msc-btn.btn-secondary.btn-block {
                        margin-bottom: 27px;
                        height: auto;
                        font-size: $msv-font-size-l;
                    }
                }
            }
        }

        .ms-search {
            display: flex;
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            margin-right: $header-search-margin-right-tablet;
            border: none;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: block;

                .ms-search__icon {
                    display: flex;
                    align-items: center;
                    color: var(--msv-header-font-color);
                    font-weight: normal;
                    align-items: center;

                    @media (max-width: $msv-breakpoint-l - 1) {
                        padding: 0;

                        &::before {
                            //font-size: 22px;
                        }
                    }

                    .ms-search__icon-text {
                        display: none;
                    }
                }
            }
        }

        .ms-profile-button-text {
            @include visually-hidden();
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);
            width: $header-icon-width;

            &::before {
                @include font-body-regular-m();
                @include msv-icomoon-icon();
                content: $ico-Account-filled;
                color: $msv-gray-900;
            }
        }

        .ms-header__switchcustomer-button-text {
            >div {
                @include basicTruncation;
                @include font-body-regular-s();

                &.ms-header__shoppingas-text {
                    @include font-body-bold-s();
                    display: block;
                }
            }
        }
    }

    @media (max-width: ($msv-breakpoint-m)-1) {
        &__container {
            .ms-header__topbar {
                .desktop-navicon-container {
                    display: none;
                }

                .ms-search {
                    margin-right: auto;
                }

                .ms-header__account-info {
                    // display: none;
                    margin-right: 0;
                    background-color: $msv-blue;
                }

                .ms-header__preferred-store-btn {
                    margin-right: 0;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }
            }
        }
    }


    /*.ms-header__modal {
        @media (min-width: ($msv-breakpoint-m)-1) {
            display: none;
        }

        .msc-modal {
            .ms-header__mobile-hamburger {
                background: var(--msv-header-bg);
                height: 100%;
                margin: 0;
                max-width: none;

                .msc-modal__content {
                    height: 100%;
                    padding: $header-modal-mobile-hamburger-content-padding;
                    background: var(--msv-header-bg);
                    color: var(--msv-header-font-color);

                    .ms-header__mobile-hamburger-menu-body {
                        padding: $header-modal-mobile-hamburger-body-padding;

                        .msc-btn {
                            font-size: 16px;
                            height: unset;
                            line-height: 22px;
                            padding: $header-modal-mobile-hamburger-body-button-padding;
                        }
                    }

                    .msc-modal__header {
                        display: block;
                        height: 18px;

                        .msc-modal__close-button {
                            color: var(--msv-header-font-color);
                            right: 0;
                            margin: 0;
                            opacity: 1;
                            padding-right: $header-modal-mobile-close-button-padding-right;
                            padding-top: $header-modal-mobile-close-button-padding-top;
                            z-index: 1000;

                            &:focus {
                                outline-offset: 0;
                            }

                            &::before {
                                @include msv-icon();
                                content: $msv-Cancel;
                                font-size: var(--msv-header-icon-font-size);
                                position: unset;
                                text-align: center;
                                vertical-align: text-bottom;
                            }

                            span {
                                display: none;
                            }
                        }
                    }
                }

                .ms-header__mobile-hamburger-menu-links {
                    display: none;
                }
            }
        }
    }*/

    .skip-to-main {
        @include font-body-regular-s();
        color: $msv-black;
    }
}

// ------ UNIQUE CLASSES

.header-country-picker {

    .mobile-navicon-container, 
    .desktop-navicon-container,
    .icon-wrapper {
        display: none !important;
    }  
    
    .ms-header__mobile-logo,
    .ms-header__logo {
        margin: auto !important;
    }
}