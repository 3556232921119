.wishlist-btn {
    #buttonDismiss {
        @include add-icon($msv-Cancel);
    }
    .msc-add-to-wishlist {
        @include add-icomoon-icon($ico-Heart, before);
        @include icon-button-light();
        margin: 0 auto;

        &::before {
            // font-size: $msv-icon-size-m;
            color: /* var(--msv-buybox-msc-add-wishlist-icon); */ $msv-gray-900;
        }

        &__removing {
            @include add-icomoon-icon($ico-Heart-filled, before);
            @include icon-button-light();
            // font-size: $msv-icon-size-m;
            margin: 0 auto;

            &::before {
                color: $wishlist-red;
            }
        }

        &:hover {
            // @include add-icomoon-icon($ico-Heart, before);
        }
    }
}
.msc-product__dimensions:has(> .Custom_additionalSwatces) {
    display: flex;
    align-items: center;
}
.Custom_additionalSwatces {
    display: flex;
    padding-left: 0.25em;
}
