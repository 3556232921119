// Import bootstrap css

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/responsive-breakpoints";
@import "layout";

.row {
    margin: 0;
}

div[class^="col"] {
    padding: 0;
    margin: 0;
}

// Container width
body .container {
    padding: $msv-layout-container-padding-m;

    @media screen and (max-width: $msv-breakpoint-m) {
        padding: $msv-layout-container-padding-m;
    }

    @media screen and (min-width: $msv-breakpoint-m + 1) {
        padding: $msv-layout-container-padding-l;
        max-width: $msv-breakpoint-xl;
    }

    // @media screen and (min-width: $msv-breakpoint-s) {
    //     max-width: $msv-breakpoint-xl;
    //     width: 100%;
    // }
}

.ms-footer .default-container {
    max-width: 100%;
} 
