@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/00-settings/icons.scss";

//Variables
$site-picker-desktop-list-bg-color: #F3F3F3;
$site-picker-list-item-img-padding: 0px 10px 1px 0;
$site-picker-list-margin: 5px;
$site-picker-list-item-padding-top: 10px;
$site-picker-list-item-padding-bottom: 10px;
$site-picker-list-item-padding-left: 10px;
$site-picker-list-item-padding-right: 10px;
$site-picker-line-height: 40px;
$site-picker-height: 48px;
$site-picker-transparent: transparent;

//style presets
:root {
    --ms-site-picker-font-size: 14px;
    --ms-site-picker-gray-500: #{$msv-gray-500};
    --ms-site-picker-bg-color: #{$site-picker-transparent};
    --ms-site-picker-desktop-list-bg-color: #{$site-picker-desktop-list-bg-color};
    --ms-site-picker-mobile-list-bg-color: #{$msv-white};
    --ms-site-picker-font-color: #{$msv-black};
}

.ms-site-picker {
    z-index: 1015;
    margin-left: 15px;
    align-self: flex-start;
    align-items: self-end;
    font-size: var(--ms-site-picker-font-size);
    height: $site-picker-height;

    &__button {
        @include font-body-regular-m();
        font-size: 14px;
        background-color: var(--ms-site-picker-bg-color);
        border: none !important;
        color: var(--msv-header-font-color);
        cursor: pointer;
        height: 100%;
        width: 100%;
        min-width: 85px;
        display: flex !important;
        align-items: center !important;

        &:focus {
            border: 1px dashed var(--ms-site-picker-gray-500);
            outline: none;
        }
    }

    &__span {
        &::after {
            @include msv-icon();
            color: var(--msv-header-font-color);
            content: $msv-ChevronDown;
            margin: 0 8px;
            line-height: $site-picker-line-height;
            vertical-align: middle;
            font-size: $msv-icon-size-xs;
        }
    }

    &__list {
        background-color: var(--ms-site-picker-mobile-list-bg-color);
        display: block;
        list-style-type: none;
        padding-left: 0;
        margin: $site-picker-list-margin;
        width: 100%;

        @media (min-width: $msv-breakpoint-m) {
            position: absolute;
            width: 100%;
            bottom: 42px;
        }

        &__item {
            cursor: pointer;

            &__link {
                color: var(--ms-site-picker-font-color);
                font-size: var(--ms-site-picker-font-size);
                flex-direction: row-reverse;
                justify-content: flex-end;
                padding-bottom: $site-picker-list-item-padding-bottom;
                padding-left: $site-picker-list-item-padding-left;
                padding-top: $site-picker-list-item-padding-top;
                padding-right: $site-picker-list-item-padding-right;
                align-items: flex-end;
                width: 100%;

                &:focus {
                    border: 1px dashed var(--ms-site-picker-gray-500);
                    outline: none;
                }
            }

            &__img {
                padding: $site-picker-list-item-img-padding;
                width: 40px;
                height: auto;
            }
        }
    }
}

.ms-site-picker {
    margin-left: 0;
    height: auto;
}

.ms-site-picker__drawer {
    .collapse {
        position: absolute;
        bottom: 52px;
        width: 80%;
        background-color: #f7f6f4;
        margin-left: -20px;
        padding: 12px 0px;

        a {
            background-color: inherit;
            padding: 12px 20px;
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            position: static;
            background-color: var(--ms-site-picker-mobile-list-bg-color);
            // margin-left: 0;
            padding: 0;
            margin: 0 auto;
            width: 60%;
            
            a {
                padding: 12px 20px;
            }
        }
    }

    .drawer__button {
        @include font-body-light-m();
        font-size: 14px;
        background-color: var(--ms-site-picker-bg-color);
        border: none;
        color: var(--ms-site-picker-font-color);
        cursor: pointer;
        width: 50%;
        font-size: 14px;
        padding: 0px;
        height: $site-picker-height;
        outline-offset: -1px;
        display: flex;
        align-items: center;    

        &:hover {
            background-color: var(--ms-site-picker-bg-color);
            border: none;
            color: var(--ms-site-picker-font-color);
            box-shadow: none;
        }

        &:focus {
            background-color: var(--ms-site-picker-bg-color);
            border: none;
            color: var(--ms-site-picker-font-color);
            box-shadow: none !important;
        }

        &:active {
            background-color: var(--ms-site-picker-bg-color) !important;
            border: none;
            color: var(--ms-site-picker-font-color) !important;
            box-shadow: none !important;
        }

        &.drawer__button.msc-btn.btn-secondary.btn-block:active:focus {
            background-color: var(--ms-site-picker-bg-color) !important;
            border: none;
            color: var(--ms-site-picker-font-color) !important;
            box-shadow: none !important;
        }

        &.drawer__button.msc-btn.btn-secondary.btn-block {
            display: flex;
            align-items: center;
        }

        &:has(.collapsing) {
            background-color: var(--ms-site-picker-bg-color);
            border: none;
            color: var(--ms-site-picker-font-color);
            box-shadow: none;
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // width: 50%;
            margin: auto;
        }

        .drawer__buttontext {
            float: left;
            margin-right: 20px;
        }

        .ms-site-picker__drawer-close {
            // position: absolute;
            // right: 10px;
            // top: 24px;

            &::after {
                @include msv-icon();
                color: var(--msv-header-font-color);
                content: $msv-ChevronDown;
            }
        }

        .ms-site-picker__drawer-open {
            // position: absolute;
            // right: 10px;
            // top: 24px;

            &::after {
                @include msv-icon();
                color: var(--msv-header-font-color);
                content: $msv-ChevronUp;
            }
        }
    }

    .ms-site-picker__list__item__link {
        flex-direction: row-reverse;
        justify-content: flex-end;
        background-color: var(--ms-site-picker-mobile-list-bg-color);
        color: var(--ms-site-picker-font-color);
        height: $site-picker-height;
        font-size: 14px;
        padding-bottom: $site-picker-list-item-padding-bottom;
        padding-left: 20px;
        padding-right: $site-picker-list-item-padding-right;
        align-items: center;
        width: 100%;

        &:focus {
            border: 1px dashed var(--ms-site-picker-gray-500);
            outline: none;
        }

        .ms-site-picker__list__item__text {
            margin: auto 0;
        }
    }
}