@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/icons.scss";
@import "../common/02-generic/button.scss";
@import "../common/01-tools/layout.scss";

$b2b-sign-in-heading-margin-bottom: 28px;
$b2b-sign-in-content-max-width: 984px;
$b2b-sign-in-content-quotes-font-size: 100px;
$b2b-sign-in-content-quotes-line-height: 80px;
$b2b-sign-in-content-quotes-margin: 40px;
$b2b-sign-in-content-additional-content-margin-top: 49px;
$b2b-sign-in-content-paragraph-padding-right: 24px;
$b2b-sign-in-content-links-padding-left: 24px;
$b2b-sign-in-content-links-padding-right: 24px;
$b2b-sign-in-banner-details-margin: 120px 0;
$b2b-sign-in-banner-details-desktop-padding-left: 80px;
$b2b-sign-in-banner-details-desktop-padding-top: 92px;
$b2b-sign-in-banner-details-desktop-padding-bottom: 82px;
$b2b-sign-in-banner-details-content-desktop-max-width: 455px;
$b2b-sign-in-banner-details-content-desktop-margin-bottom: 10px;
$b2b-sign-in-container-desktop-width: 350px;
$b2b-sign-in-container-desktop-right-space: 50px;
$b2b-signin-desktop-signup-link-padding: 0 0 0 5px;

$b2b-sign-in-banner-details-ldesktop-padding-left: 80px;
$b2b-sign-in-banner-details-ldesktop-padding-top: 102px;
$b2b-sign-in-banner-details-ldesktop-padding-bottom: 102px;
$b2b-sign-in-banner-details-content-ldesktop-max-width: 498px;
$b2b-sign-in-banner-details-content-ldesktop-margin-bottom: 28px;
$b2b-sign-in-container-ldesktop-width: 550px;
$b2b-sign-in-container-ldesktop-right-space: 103px;
$b2b-sign-in-container-width: 550px;
$b2b-sign-in-container-padding: 80px 60px;
$b2b-sign-in-forgot-password-margin-top: 28px;
$b2b-sign-in-button-margin: 28px 0;
$b2b-sign-in-image-list-item-padding: 0 17px;
$b2b-signin-container-signin-ldesktop-bottom-margin: -80px;
$b2b-signin-container-signin-desktop-bottom-margin: 0;
$b2b-signin-container-signin-right-margin: 103px;
$b2b-signin-container-signin-border-radius: 10px;
$b2b-signin-mobile-bottom: 25px;
$b2b-signin-mobile-margin-top: -1px;
$b2b-signin-mobile-padding: 0 40px;
$b2b-signin-mobile-padding-bottom: 70px;
$b2b-signin-mobile-container-padding: 40px 0;
$b2b-signin-mobile-section-padding: 0 20px;
$b2b-signin-mobile-signIn-section-padding: 0 80px;
$b2b-sign-in-doublequotes-margin: 20px;
$b2b-signin-mobile-container-border-radius: 6px;
$b2b-signin-tablet-signup-section-padding-right: 30px;
$b2b-signin-tablet-signup-link-padding-left: 5px;

.b2bsignin {
    .signin-container .ms-text-block {
        @include font-heading-h1-s();
        color: $msv-gray-20;
        margin-bottom: $b2b-sign-in-heading-margin-bottom;
    }

    .ourBuisnessPartner {
        .ms-image-list {
            .ms-image-list__title {
                color: $msv-gray-20;
                text-align: center;

                @include font-heading-h1-s();
            }

            .ms-image-list__list {
                .ms-image-list__list-item {
                    max-width: calc(3 * #{$msv-column-width-s});
                    padding: $b2b-sign-in-image-list-item-padding;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        max-width: inherit;
                    }
                }
            }
        }
    }

    .otherBuisnessPartner .ms-text-block {
        color: $msv-gray-20;

        @include font-heading-h1-s();
    }

    .adventurework-container {
        .ms-content-block {
            &__image {
                img {
                    margin: auto;
                }
            }

            &__text {
                @include font-heading-h4-l();
                text-align: center;
                display: flex;
                color: $msv-gray-20;
                width: $msv-width-full;
                max-width: $b2b-sign-in-content-max-width;
                margin: 0 auto;

                .left-doublequotes,
                .right-doublequotes {
                    font-size: $b2b-sign-in-content-quotes-font-size;
                    line-height: $b2b-sign-in-content-quotes-line-height;
                    color: $msv-blue;
                }

                .left-doublequotes {
                    margin-right: $b2b-sign-in-content-quotes-margin;
                }

                .right-doublequotes {
                    margin-left: $b2b-sign-in-content-quotes-margin;
                }
            }

            &__details__additional-content {
                margin-top: $b2b-sign-in-content-additional-content-margin-top;
                text-align: center;

                @include font-body-regular-m();

                &__container {
                    display: inline-flex;
                    justify-content: center;
                }

                &__paragraph {
                    @include font-body-regular-m();
                    padding-right: $b2b-sign-in-content-paragraph-padding-right;
                }

                &-cta {
                    @include font-body-regular-m();

                    &-links {
                        padding-left: $b2b-sign-in-content-links-padding-left;
                        padding-right: $b2b-sign-in-content-links-padding-right;

                        &:nth-child(1) {
                            border-left: 1px solid $msv-gray-940;
                        }
                    }
                }
            }
        }
    }

    .b2bsignin-hero-banner {
        height: 820px;

        .ms-content-block__image,
        .ms-content-block__image img {
            height: 820px;
        }

        .ms-content-block__details {
            background-color: $msv-black;
            width: $msv-width-full;
            opacity: 0.8;
            height: 78%;
            justify-content: center;
            margin: $b2b-sign-in-banner-details-margin;
            display: block;

            .ms-content-block__title {
                @include font-heading-h1-s();
                text-align: left;
                color: $msv-white;
            }

            .ms-content-block__text {
                @include font-body-regular-m();
                color: $msv-white;
                text-align: left;
            }

            .ms-content-block__details__additional-content {
                position: static;
                margin-left: 0;
                justify-content: flex-start;
            }
        }
    }

    .signin-container {
        .ms-sign-in {
            width: $b2b-sign-in-container-width;
            position: absolute;
            z-index: 1;
            right: $b2b-signin-container-signin-right-margin;
            background-color: $msv-white;
            border-radius: $b2b-signin-container-signin-border-radius;

            &__container {
                display: flex;
                flex-direction: column;
                padding: $b2b-sign-in-container-padding;
                margin: 0;

                .ms-sign-in__sign-in-section {
                    margin-right: 0;

                    .ms-sign-in__sign-in-heading {
                        color: $msv-gray-20;

                        @include font-heading-h3-l();

                        text-align: left;
                    }

                    .ms-sign-in__account-item-input {
                        font-size: $msv-font-size-l;
                        font-style: $msv-font-weight-normal;
                        font-weight: $msv-font-weight-400;
                        line-height: $msv-line-height-l;
                        text-align: left;
                        border: 1px solid $msv-gray-300;
                        background: $msv-white;
                    }

                    .ms-sign-in__forget-password {
                        @include font-body-regular-s();
                        text-align: left;
                    }

                    .ms-sign-in__sign-in-disclaimer {
                        @include font-body-regular-s();
                        text-align: left;

                        a {
                            text-decoration: underline;
                        }
                    }

                    .ms-sign-in__account-items.localAccount {
                        .ms-sign-in__account-item {
                            order: 0;
                        }

                        .ms-sign-in__forget-password {
                            order: 1;
                            margin-top: $b2b-sign-in-forgot-password-margin-top;
                        }

                        .ms-sign-in__sign-in-button.msc-btn {
                            order: 3;
                            margin: $b2b-sign-in-button-margin;
                        }

                        .ms-sign-in__sign-in-disclaimer {
                            order: 2;
                            padding-top: 18px;
                        }
                    }

                    .ms-sign-in__social-accounts {
                        display: none;
                    }
                }

                .ms-sign-in__sign-up-section {
                    display: flex;
                    border-left: none;
                    padding-left: 0;
                    padding-bottom: 20px;

                    .ms-sign-in__sign-up-heading {
                        @include font-body-regular-s();
                        flex: 0 0 auto;
                    }

                    .ms-sign-in__sign-up-description {
                        display: none;
                    }

                    .ms-sign-in__sign-up-link {
                        @include link-regular-s();
                        background: transparent;
                        padding: $b2b-signin-desktop-signup-link-padding;
                        justify-content: left;
                        height: auto;
                        margin-bottom: auto;
                        text-transform: none;

                        &:hover {
                            box-shadow: none;
                        }
                    }
                }

                .ms-sign-in__employee-sign-in-section {
                    display: flex;
                    border-left: none;
                    padding-left: 0;
                    flex-direction: column;

                    &__line-strike {
                        display: block;
                        text-align: center;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;

                        span {
                            position: relative;
                            display: inline-block;
                            font-size: 20px;
                            font-weight: bold;

                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                width: 9999px;
                                height: 1px;
                                background: $msv-gray-50;
                            }

                            &::before {
                                right: 100%;
                                margin-right: 15px;
                            }

                            &::after {
                                left: 100%;
                                margin-left: 15px;
                            }
                        }
                    }

                    .ms-sign-in__b2b-social-accounts {
                        display: block;
                        border: none;
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        .signin-container {
            .ms-sign-in {
                .ms-sign-in__container {
                    .ms-sign-in__sign-up-section {
                        padding-right: $b2b-signin-tablet-signup-section-padding-right;
                        padding-top: unset;
                        flex-direction: row;

                        .ms-sign-in__sign-up-link {
                            padding-left: $b2b-signin-tablet-signup-link-padding-left;
                        }

                        .ms-sign-in__sign-up-heading {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-lg) {
        .signin-container {
            .ms-sign-in {
                width: $b2b-sign-in-container-ldesktop-width;
                bottom: $b2b-signin-container-signin-ldesktop-bottom-margin;
                right: $b2b-sign-in-container-ldesktop-right-space;
            }
        }

        .b2bsignin-hero-banner {
            .ms-content-block__details {
                padding-left: $b2b-sign-in-banner-details-ldesktop-padding-left;
                padding-top: $b2b-sign-in-banner-details-ldesktop-padding-top;
                padding-bottom: $b2b-sign-in-banner-details-ldesktop-padding-bottom;
            }
        }

        .ms-content-block__title {
            margin-bottom: $b2b-sign-in-banner-details-content-ldesktop-margin-bottom;
            max-width: $b2b-sign-in-banner-details-content-ldesktop-max-width;
        }

        .ms-content-block__text {
            margin-bottom: $b2b-sign-in-banner-details-content-ldesktop-margin-bottom;
            max-width: $b2b-sign-in-banner-details-content-ldesktop-max-width;
        }
    }

    @media (min-width: $msv-breakpoint-l) and (max-width: $msv-breakpoint-lg) {
        .signin-container {
            .ms-sign-in {
                width: $b2b-sign-in-container-desktop-width;
                bottom: $b2b-signin-container-signin-desktop-bottom-margin;
                right: $b2b-sign-in-container-desktop-right-space;

                .ms-sign-in__container {
                    .ms-sign-in__sign-up-section {
                        flex-direction: column;

                        .ms-sign-in__sign-up-link {
                            padding-left: 0;
                        }

                        .ms-sign-in__sign-up-heading {
                            margin-top: auto;
                        }
                    }
                }
            }
        }

        .b2bsignin-hero-banner {
            .ms-content-block__details {
                padding-left: $b2b-sign-in-banner-details-desktop-padding-left;
                padding-top: $b2b-sign-in-banner-details-desktop-padding-top;
                padding-bottom: $b2b-sign-in-banner-details-desktop-padding-bottom;
            }
        }

        .ms-content-block__title {
            margin-bottom: $b2b-sign-in-banner-details-content-desktop-margin-bottom;
            max-width: $b2b-sign-in-banner-details-content-desktop-max-width;
        }

        .ms-content-block__text {
            margin-bottom: $b2b-sign-in-banner-details-content-desktop-margin-bottom;
            max-width: $b2b-sign-in-banner-details-content-desktop-max-width;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        .b2bsignin-hero-banner {
            .ms-content-block__details {
                margin: 0;
            }

            .ms-content-block__details.withImage {
                padding-left: $b2b-sign-in-content-quotes-margin;
                background-color: $msv-gray-20;
                opacity: 1;

                .ms-content-block__title {
                    max-width: fit-content;
                }

                .ms-content-block__text {
                    max-width: fit-content;
                }

                .ms-content-block__details__additional-content {
                    margin-top: 0;
                }
            }
        }

        .ms-sign-in__container .ms-sign-in__sign-in-section .ms-sign-in__account-items .ms-sign-in__account-item-input {
            @include font-body-regular-s();
        }

        .signin-container {
            .ms-sign-in {
                background-color: $msv-gray-20;
                border-radius: 0;
                bottom: $b2b-signin-mobile-bottom;
                width: auto;
                position: initial;
                padding: $b2b-signin-mobile-padding;
                padding-bottom: $b2b-signin-mobile-padding-bottom;
                margin-top: $b2b-signin-mobile-margin-top;

                .ms-sign-in__container {
                    background-color: $msv-white;
                    padding: $b2b-signin-mobile-container-padding;
                    border-radius: $b2b-signin-mobile-container-border-radius;

                    .ms-sign-in__sign-up-section {
                        flex-direction: row;

                        .ms-sign-in__sign-up-link {
                            padding-left: $b2b-signin-tablet-signup-link-padding-left;
                        }
                    }

                    .ms-sign-in__sign-in-section {
                        padding: $b2b-signin-mobile-signIn-section-padding;
                    }
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-m - 1) {
        .signin-container {
            .ms-sign-in {
                width: auto;

                .ms-sign-in__container {
                    background-color: $msv-white;
                    padding: $b2b-signin-mobile-container-padding;

                    .ms-sign-in__sign-in-section {
                        align-items: center;
                        padding: $b2b-signin-mobile-section-padding;
                    }

                    .ms-sign-in__sign-up-section {
                        padding: 0 20px 20px 20px;
                        flex-direction: column;
                        margin: 0;
                        align-items: flex-start;

                        .ms-sign-in__sign-up-heading {
                            margin-top: auto;
                        }

                        .ms-sign-in__sign-up-link {
                            padding: 0;
                        }
                    }

                    .ms-sign-in__employee-sign-in-section {
                        align-items: center;
                        padding: $b2b-signin-mobile-section-padding;
                    }
                }
            }
        }

        .adventurework-container {
            .ms-content-block__text .left-doublequotes {
                margin-right: $b2b-sign-in-doublequotes-margin;
            }

            .ms-content-block__text .right-doublequotes {
                margin-left: $b2b-sign-in-doublequotes-margin;
            }
        }
    }

    .ms-sign-in__item-error[aria-hidden="false"] + input {
        border-left: 4px solid var(--msv-form-error-font-color);
    }
}
