@import "../common/00-settings/colors.scss";
@import "../common/00-settings/responsive-breakpoints.scss";

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
        height: 40px;
        width: 75%;
        margin-bottom: 20px;
    }

    .apple-pay-button-black {
        -apple-pay-button-style: $msv-black;
    }

    .apple-pay-button-white {
        -apple-pay-button-style: $msv-white;
    }

    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }

    .ms-checkout__main .apple-pay-button {
        width: $msv-width-full;
        max-width: 270px;
    }

    .msc-order-summary__checkout .apple-pay-button {
        height: 44px;
        border-radius: 30px;

        @media screen and (max-width: $msv-breakpoint-l) {
            width: calc(100% - 24px);
            margin: 12px 12px;
        }

        @media screen and (min-width: $msv-breakpoint-l) {
            width: calc(100% - 65px);
            margin: 12px 34px;
        }
    }

    .ms-checkout-express-payment-container .ms-checkout-apple-pay {
        padding-top: 32px;
    }

    .ms-checkout-google-pay {
        float: left;
        width: 270px;
        height: 40px;
        margin-right: 22px;
        margin-bottom: 28px;
    }

    @media screen and (max-width: $msv-breakpoint-lg) {
        .ms-checkout-apple-pay {
            width: 270px;
        }
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 6px;
        padding: 10px 16px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 46px;
        max-height: 64px;
        font-size: 18px;
        line-height: 1.3333;
    }

    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: $msv-black;
    }

    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: $msv-white;
    }

    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: $msv-white;
        border: 0.5px solid $msv-black;
    }
}
