$non-mobile: 600px;

.ms-search-result-container {

    //* { box-shadow: 0 0 2px red inset; }

    &__category-nav-section {
        margin: 18px 0;
        padding: 0;
        display: flex;
        justify-content: center;

        @media (min-width: $non-mobile) {
            margin: 18px 0 42px;
        }

        .ms-search-result-container__title {
            margin: 0;
            padding: 0;
        }

        .ms-search-result__collection-title-text {
            display: block; //NOTE: org span (inline) 
            font-size: 24px;
            font-weight: 700;
            line-height: normal;

            @media (min-width: $non-mobile) {
                font-size: 32px;
            }
        }
    }

    &__title.result-count-category:last-child {
        display: none;
    }

    .ms-refiner-heading {
        margin: 0;
        padding: 0;

        &:not(.lock-Filter) {
            height: initial;
            min-height: 60px;

            .ms-refiner-heading_title {
                height: initial;
                min-height: 60px;
            }
        }

        .msc-btn {
            border: 0 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            letter-spacing: normal !important;
            text-transform: uppercase;
            margin: 0 0 0 42px !important;

            // &:after {
            //     content: "";
            //     padding: 0 0 0 14px;
            // }
        }

        .ms-search-result-wrapper-sort-by-category {
            margin: 0 42px 0 0 !important;
        }

        .ms-search-result-container__Sort-by-category {
            margin: 0;
            padding: 0;
        }

        .reviews-list-sort-by {
            margin: 0 14px 0 0;
            padding: 0;
            font-size: 14px !important;
            font-weight: 400 !important;
            letter-spacing: normal !important;
            text-transform: uppercase;
        }

        .reviews-list-dropdown {
            margin: 0;
            padding: 0;
            border: 0;
            width: auto;
            font-size: 14px !important;
            font-weight: 700 !important;
            letter-spacing: normal !important;
            text-transform: uppercase;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        }
    }

    .msc-sort-filter-wrapper {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 14px;
        // background: #F6F6F6 !important; //NOTE: reset .lock-Filter

        .msc-button {
            width: initial;
            min-width: initial;
            height: initial;
            background: transparent;
            border: 0 !important;
            vertical-align: middle;
            font-size: 14px !important;
            font-weight: 400 !important;
            letter-spacing: normal !important;
            text-transform: uppercase;

            &:after {
                padding: 0 0 0 14px;
            }
        }
    }

    .ms-search-result-container__Products {
        margin: 14px !important; //NOTE: Mobile reset (.default-container.fluid .ms-search-result-container)
        padding: 0;

        @media (min-width: $non-mobile) {
            margin: 32px 42px 46px 42px !important; //NOTE: Non-mobile re-define (.default-container.fluid .ms-search-result-container)
        }

        .list-unstyled {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 12px 10px;

            @media (min-width: $non-mobile) {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 46px 14px;
            }

            .ms-product-search-result__item {
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                width: auto;
                text-align: left;

                &:hover, &:focus {
                    box-shadow: none;
                }

                > div:first-child {
                    flex: 1;
                }

                .msc-product {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .msc-product__image {
                    flex: 1;
                    height: auto;

                    .msc-empty_image.msc_image {
                        width: 100%;
                        height: auto;
                        aspect-ratio: 1 / 1;
                    }
                }

                .msc-product__details {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0 0 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 400;

                    @media (min-width: $non-mobile) {
                        margin: 22px 0 0 0;
                    }

                    .msc-product__title {
                        margin: 2px 0 0 0;
                        padding: 0;
                        order: 1;
                        font-size: 12px;
                        font-weight: 400;

                        @media (min-width: $non-mobile) {
                            margin: 6px 0 0 0;
                        }
                    }

                    .msc-price {
                        display: block;
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        font-weight: 400;

                        .msc-price__actual {
                            display: block;
                        }
                    }

                    .discount-box {
                        height: auto;
                        padding: 8px;

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: normal;
                        }
                    }
                }

                .wishlist-btn {
                    position: absolute;
                    top: 10px;
                    right: 10px;

                    @media (min-width: $non-mobile) {
                        top: 14px;
                        right: 14px;
                    }  

                    .msc-add-to-wishlist {
                        margin: -8px;
                        padding: 8px;
                        width: initial;
                        min-width: initial;
                        height: initial;
                        border: 0;
                        border-radius: 0;

                        &:before {
                            font-size: 17px; //NOTE: h: 17px
                            line-height: normal;

                            @media (min-width: $non-mobile) {
                                font-size: 18px; //NOTE: h: 18px
                            }
                        }
                    }

                    .ms-wishlist-items__product-status {
                        display: none; //Note: Show later when styled
                    }
                }

                .msc-product__dimensions {
                    margin: 4px 0 0 0;
                    padding: 0;

                    @media (min-width: $non-mobile) {
                        margin: 8px 0 0 0;
                    }

                    .msc-swatch-container {
                        min-height: auto;
                        justify-content: start;
                        gap: 6px;

                        .msc-swatch-container__item {     
                            width: 12px !important;
                            height: 12px !important;
                            min-height: 12px !important;
                            min-width: 12px !important;
                            margin: 0 !important;
                            padding: 0 !important;

                            @media (min-width: $non-mobile) {
                                width: 19px !important;
                                height: 19px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}