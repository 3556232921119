@import "../common/00-settings/colors.scss";
@import "../common/00-settings/icons.scss";
@import "../common/00-settings/font.scss";
@import "../common/02-generic/button.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/01-tools/layout.scss";
@import "../common/02-generic/image.scss";
@import "../common/03-components/modal.scss";
// @import "../common/02-generic/reset.scss";

$default-minicart-empty-image-width: 115px;
$default-minicart-empty-image-height: $default-minicart-empty-image-width * (4/3);
$default-minicart-mobile-empty-image-width: 78px;
$default-minicart-mobile-empty-image-height: $default-minicart-mobile-empty-image-width * (4/3);
$msv-minicartmodal-content-height: 100%;
$msv-minicart-max-width: 488px;
$msv-minicart-icon-count-width: 30%;
$msv-minicart-icon-close-button-margin-right: 21px;
$msv-minicart-icon-close-button-width: 24px;
$msv-minicart-lines-item-padding: 16px 0 22px;
$msv-minicart-footer-width: 100%;
$msv-minicart-footer-margin-left: 16px;
$msv-minicart-footer-margin-right: 16px;
$msv-minicart-footer-margin-bottom: 12px;
$msv-minicart-footer-margin-left-t: 24px;
$msv-minicart-footer-margin-right-t: 24px;
$msv-emptycart-details-heading-margin: 50px 74px 27px 92px;
$msv-emptycart-details-text-margin: 27px 113px 0 122px;
$msv-emptycart-details-links-margin: 84px auto 84px;
$msv-minicart-body-padding: 0 25px 10px 16px;
$msv-mini-cart-promo-content-image-margin: 30px 21px;
$ms-cart-icon-body-margin-right: 4px;
$cartline-item-bopis-method-icon-margin-right: 8px;
$ms-cartline-attributes-font-size: 14px;

//style presets
:root {
    //Background
    --msv-mini-cart-bg: #{$msv-white};

    //Body
    --msv-mini-cart-product-title-color: #{$msv-gray-20};
    --msv-mini-cart-count-color: #{$msv-gray-40};

    //Border
    --msv-mini-cart-icon-header-border-color: #{$msv-gray-50};

    //Promo content
    --msv-mini-cart-promo-content-bg-color: #{$msv-blue-20};
    --msv-mini-cart-promo-content-link-bg-color: #{$msv-blue-10};
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.msc-modal.fade .msc-modal__dialog.ms-cart-icon__minicartmodal-container {
    right: -($msv-column-width-s * 4);

    @media (max-width: $msv-breakpoint-l) {
        right: -($msv-column-width-m * 4);
    }

    @media (max-width: $msv-breakpoint-m) {
        right: -($msv-column-width-l * 4);
    }
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.msc-modal.fade.show .msc-modal__dialog.ms-cart-icon__minicartmodal-container {
    right: 0;
}

.ms-cart-icon__warning-message-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 16px;
    padding: 20px;
    gap: 12px;

    background: $msv-blue-20;
    border-radius: 10px;

    /* Inside auto layout */

    .msi-InfoCircle {
        @include add-icon($msv-information);

        &::before {
            font-size: 20px;
            top: 2px;
            left: 2px;
            position: relative;
            vertical-align: text-bottom;
        }
    }

    &__content {
        font-family: "Rubik";
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--msv-mini-cart-notification);

        order: 1;
        flex-grow: 1;

        &__title {
            font-weight: bold;
            display: block;
        }
    }
}

.ms-cart-icon__minicartmodal-container {
    position: fixed;
    background: var(--msv-mini-cart-bg);
    width: calc(5 * #{$msv-column-width-s});
    height: 100%;
    z-index: 1060;
    display: block;
    transform: none;
    right: 0;
    top: 0;
    left: auto;
    max-width: $msv-minicart-max-width;

    &.show {
        opacity: 1;
    }

    .msc-modal__content {
        padding: 0;
        border: none;
        height: $msv-minicartmodal-content-height;
        display: flex;
    }

    .ms-cart-icon {
        &__header {
            flex-direction: row;
            // border-bottom: 1px solid var(--msv-mini-cart-icon-header-border-color);
            padding: 21px 15px 24px 16px;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: unset;
        }

        &__flyout-title {
            @include font-heading-h2-l();

            @media (max-width: $msv-breakpoint-m) {
                @include font-heading-h2-s();
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                @include font-heading-h2-m();
            }
        }

        &__count {
            color: var(--msv-mini-cart-count-color);
            width: $msv-minicart-icon-count-width;
            // padding-left: 9px;
            // margin-top: 4px;

            @include font-body-regular-s();
            font-size: $ms-cartline-attributes-font-size;
        }

        &__btn-close {
            background-color: transparent;
            border: 0;
            color: $msv-gray-20;
            margin-right: $msv-minicart-icon-close-button-margin-right;
            position: absolute;
            right: 0;
            justify-content: center;
            display: flex;
            width: $msv-minicart-icon-close-button-width;

            @include vfi();
            @include add-icon($msv-Cancel, before);

            &::before {
                font-size: $msv-icon-size-m;
            }
        }

        &__body {
            height: 100%;
            max-height: 100%;
            display: block;
            margin-bottom: unset;
            padding: $msv-minicart-body-padding;

            @media (max-width: ($msv-breakpoint-m)) {
                padding: 0px 16px 10px 16px;
            }
        }

        &__cart-lines {
            overflow-y: auto;
            overflow-x: hidden;
            height: auto;

            .msc-cart-lines-item {

                &:first-child {
                    padding-top: 0;
                } 

                border-bottom: 1px solid var(--msv-mini-cart-icon-header-border-color);
                position: relative;
                padding: $msv-minicart-lines-item-padding;
                display: flex;
                flex-direction: column;

                .msc-cart-line {
                    display: flex;

                    &__product-image img {
                        max-width: none;
                        box-sizing: border-box;
                        // border: 0.5px solid var(--msv-mini-cart-icon-header-border-color);
                    }

                    &__content {
                        position: relative;

                        @media (min-width: $msv-breakpoint-m) {
                            margin-left: 22px;
                        }
                        width: 100%;

                        &__bopis-method {
                            @include font-body-regular-s();
                            color: var(--msv-mini-cart-product-title-color);
                            letter-spacing: 0.01em;
                            // margin-top: 8px;
                            display: flex;
                            justify-content: flex-end;

                            .pick-up {
                                @include add-icon($msv-shopping-bag, before);

                                &::before {
                                    margin-right: $cartline-item-bopis-method-icon-margin-right;
                                }
                            }

                            .shipping {
                                display: none;
                                // @include add-icon($msv-DeliveryTruck, before);
        
                                // &::before {
                                //     margin-right: $cartline-item-bopis-method-icon-margin-right;       
                                // }
                            }
                        }
                    }

                    &__product-price,
                    &__product-unit-price {
                        display: none;
                    }

                    &__product-title {
                        letter-spacing: 0.005em;

                        @include vfi();
                        @include font-body-regular-m();
                        display: inline-flex;
                        align-items: center;
                    }

                    &__product-variants {
                        letter-spacing: 0.01em;
                        color: var(--msv-mini-cart-count-color);

                        @include font-body-regular-s();
                        font-size: $ms-cartline-attributes-font-size;
                        text-transform: uppercase;
                        margin-top: 12px;

                        .msc-cart-line__product-variant-item {
                            color: var(--msv-mini-cart-count-color);
                        }

                        .name {
                            color: var(--msv-mini-cart-product-title-color);
                        }
                    }

                    &__quantity {
                        @include font-body-regular-s();
                        font-size: $ms-cartline-attributes-font-size;
                        margin-bottom: 20px;

                        .quantity-label {
                            color: var(--msv-mini-cart-count-color);
                        }

                        .quantity-value {
                            color: var(--msv-mini-cart-product-title-color);
                        }
                    }

                    &__product-unit-of-measure {
                        color: $msv-gray-40;
                    }

                    &__bopis-method {
                        display: none;
                    }

                    > .msc-cart-line__content {
                        .msc-cart-line__sales-agreement-prompt {
                            @include font-body-regular-s();
                            color: $msv-blue;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }

                    .msc-cart-line__content {
                        .msc-cart-line__product {
                            .msc-cart-line__sales-agreement-prompt {
                                display: none;
                            }
                        }
                    }
                }

                .msc-cart-line__product-discount {
                    display: none;
                }

                .msc-cart-line__product-savings {
                    &-actual {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        font-family: 'Conv_EuropaRegular';

                        span {
                            align-self: flex-end;
                            padding: 4px;
                        }

                        .msc-price__strikethrough {
                            text-decoration: line-through;
                        }

                        .msc-cart-line-item-product-discount,
                        .msc-cart-line__bopis-method {
                            display: none;
                        }

                        .msc-price__actual {
                            @include font-heading-h5-l();

                            @media (max-width: $msv-breakpoint-m) {
                                @include font-heading-h5-s();
                            }

                            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                                @include font-heading-h5-m();
                            }
                        }
                    }

                    &-label {
                        display: none;
                    }

                    &-text {
                        display: none;
                    }
                }

                .msc-cart-line__remove-item {
                    @include add-icon($msv-Cancel, before);

                    @include link-regular-s();
                    font-family: 'Conv_EuropaLight';


                    &::before {
                        color: $msv-gray-40;
                        margin-right: 6px;
                    }

                    background: transparent;
                    border: none;
                    flex-flow: column;
                    margin-top: -21px;
                    padding-left: 0;
                    line-height: $msv-line-height-xl;
                    width: fit-content;


                    &:focus {
                        max-width: fit-content;
                        margin-left: 2px;
                    }
                }

                .msc-cart-line__product-image {
                    display: flex;

                    @include image($default-minicart-empty-image-width,$default-minicart-empty-image-height);

                    .msc-empty_image {
                        // margin: auto;
                        border: 0.5px solid var(--msv-mini-cart-icon-header-border-color);
                    }
                }
            }
        }

        &__footer {
            width: $msv-minicart-footer-width;
            // box-shadow: $msv-depth24;
            left: 1px;
            position: relative;
            bottom: 0;
            display: inline-block;

            .ms-image-list {
                background: var(--msv-mini-cart-promo-content-bg-color);
                align-items: center;

                &__list-item {
                    display: grid;
                }

                &__link {
                    background-color: var(--msv-mini-cart-promo-content-link-bg-color);
                    margin: 0;
                    grid-column: 1/2;
                }

                &__text {
                    padding: 24px 28px;
                    grid-column: 2/3;
                    align-self: center;

                    @include font-body-regular-s();
                }

                picture {
                    margin: $msv-mini-cart-promo-content-image-margin;
                    align-self: center;
                }
            }
        }

        &__subTotalText,
        &__subtotal-message,
        &__btn-checkout,
        &__btn-gotocart,
        &__btn-guestcheckout,
        &__btn-backtoshopping {
            margin-left: $msv-minicart-footer-margin-left;
            margin-right: $msv-minicart-footer-margin-right;

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-left: $msv-minicart-footer-margin-left-t;
                margin-right: $msv-minicart-footer-margin-right-t;
            }
        }

        &__subTotalText {
            text-align: center;
            padding-top: 26px;
            text-transform: uppercase;

            @include font-body-bold-m();
            font-size: $ms-cartline-attributes-font-size;
        }

        &__subTotalPrice {
            @include font-heading-h5-l();
            font-size: $ms-cartline-attributes-font-size;
        }

        &__subtotal-message {
            text-align: center;
            margin-bottom: $msv-minicart-footer-margin-bottom;
            margin-top: 6px;

            @include font-body-regular-s();
            font-size: $ms-cartline-attributes-font-size;
            color: $msv-gray-40;
        }

        &__btn-checkout {
            display: none;
            // @include primary-button-dark();
            // width: auto !important;
            // margin-bottom: 12px;
            // margin-top: 24px;
            // display: flex;
        }

        &__btn-gotocart {
            @include secondary-button-dark();
            width: auto !important;
            margin-bottom: 24px;
            display: flex;
        }

        &__btn-guestcheckout {
            display: none;
            // @include secondary-button-dark();
            // width: auto !important;
            // margin-bottom: 12px;
            // display: flex;
        }
    }

    .ms-cart-icon__emptycart {
        margin-top: 28px;
        text-align: center;

        &_text {
            color: $msv-black;
        }

        &_icon {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ms-content-block {
        &__title {
            @include font-heading-h2-l();
            text-align: center;
            position: relative;
            margin: $msv-emptycart-details-heading-margin;

            @media (max-width: ($msv-breakpoint-m)) {
                @include font-heading-h2-s();
                margin-top: 37px;
                margin-left: 24px;
                margin-right: 24px;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                @include font-heading-h2-m();
                margin-left: 67px;
                margin-right: 86px;
            }
        }

        &__text {
            position: relative;

            @include font-body-regular-s();
            align-items: center;
            text-align: center;
            margin: $msv-emptycart-details-text-margin;

            @media (max-width: ($msv-breakpoint-m)) {
                margin-left: 38px;
                margin-right: 38px;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-left: 81px;
                margin-right: 100px;
            }
        }

        &__cta {
            position: relative;
            display: grid;
            justify-items: center;
            margin: $msv-emptycart-details-links-margin;

            @media (max-width: ($msv-breakpoint-m)) {
                margin-top: 54px;
                margin-bottom: 67px;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-top: 77px;
                margin-bottom: 90px;
            }

            .msc-cta__primary,
            .msc-cta__secondary {
                display: flex;
                flex-direction: column;
                margin-bottom: 44px;

                @include feature-link-light-m();

                letter-spacing: normal;

                &::before {
                    border-radius: 10px;
                }
            }
        }
    }

    .ms-cart-icon__btn-backtoshopping {
        @include primary-button-dark();
        margin-bottom: 24px;
        margin-top: 20px;
    }

    .ms-cart-icon__btn-section {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: $msv-breakpoint-m) {
    .ms-cart-icon__minicartmodal-container {

        .ms-cart-icon__ {

            &__btn-checkout, 
            &__btn-gotocart,
            &__btn-guestcheckout {
                width: auto;
            }
        }

        width: 100%;
        max-width: 100%;

        .msc-cart-line {
            flex-wrap: nowrap;

            &__product {
                &-quantity,
                &-savings,
                &-quantity .quantity-input {
                    flex: none;
                }
            }

            &__content {
                margin-left: 0;
            }

            .msc-cart-line__product-savings-actual {
                .msc-price__actual {
                    bottom: 20px;
                }
            }
        }

        .ms-cart-icon {
            &__cart-lines {
                overflow-y: unset;

                .msc-cart-lines-item {
                    padding-left: 0;

                    .msc-cart-line__product-savings-actual {
                        .msc-price__actual {
                            bottom: -11px;
                            right: 0;
                            left: unset;
                        }
                    }

                    .msc-cart-line__content {
                        margin-left: 16px;
                    }

                    .msc-cart-line__product-image {
                        @include image(
                            $default-minicart-mobile-empty-image-width,
                            $default-minicart-mobile-empty-image-height
                        );

                        // .msc-empty_image {
                        //     margin: auto;
                        // }
                    }
                }

                &:last-child() {
                    border-bottom: none !important;
                }
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .msc-modal__dialog:not(.msc-modal-input-required) {
        margin: 0;
    }

    .ms-cart-icon__minicartmodal .msc-modal__dialog:not(.msc-modal-input-required) .msc-modal__content {
        padding: 0;
    }
}

.ms-cart-icon__minicartmodal-close {
    .ms-cart-icon__minicartmodal-container {
        display: none;
    }
}

.ms-cart-icon-wrapper {
    border: none;
    outline: none;
    background: transparent;
}

@media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
    .ms-cart-icon__minicartmodal-container {
        width: calc(5 * #{$msv-column-width-m});

        .ms-cart-icon {
            &__cart-lines {
                .msc-cart-lines-item {
                    .msc-cart-line__product-savings-actual {
                        .msc-price__actual {
                            bottom: -11px;
                            right: 0;
                            left: unset;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $msv-breakpoint-m) and (orientation: landscape) {
    .ms-cart-icon__minicartmodal-container {
        .msc-modal__content {
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .ms-cart-icon__body {
            overflow: unset;
            height: auto;
            max-height: unset;
        }
    }
}
