@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/01-tools/layout.scss";
@import "../common/02-generic/image.scss";
@import "../common/02-generic/button.scss";
@import "../common/00-settings/icons.scss";
// @import "../common/02-generic/reset.scss";
@import "../common/02-generic/form.scss";

$msv-account-profile-toggle-button-icon-size: 36px;
$msv-account-profile-toggle-button-padding: 0 5px;
$msv-account-profile-toggle-color: $msv-blue;
$msv-account-profile-links-padding: 6px 0 6px 0;
$msv-account-profile-heading-padding-bottom: 24px;
$msv-account-profile-heading-margin-bottom: 44px;
$msv-account-profile-section-margin-bottom: 48px;
$msv-account-profile-section-heading-margin-bottom: 16px;
$msv-account-profile-section-preferences-margin-top: 41px;
$msv-account-profile-section-preferences-heading-margin-bottom: 38px;
$msv-account-profile-section-preferences-heading-padding-bottom: 22px;
$msv-account-profile-section-preferences-toggle-margin-top: 11px;
$msv-account-profile-section-preferences-web-tracking-margin-top: 48px;
$msv-account-profile-section-margin-right: 100px;
$msv-account-profile-attributes-section-margin-bottom: 20px;
$msv-account-profile-attributes-section-save-button-margin-right: 12px;
$msv-account-profile-margin-right: 53px;
$msv-account-profile-cancel-button-margin-top: 15px;
$msv-account-profile-description-margin-bottom: 30px;
$msv-account-profile-vat-margin-bottom: 30px;
$msv-account-profile-disable-opacity: 0.6;

// info message bar
$msv-account-profile-info-padding: 20px;
$msv-account-profile-info-margin-bottom: 44px;

// style presets
:root {
    --msv-account-profile-link-disabled-color: #{$msv-gray-50};

    // title
    --msv-account-landing-tile-heading-color: var(--msv-font-primary-color);

    // info message bar
    --msv-account-profile-info-message-bg: #{$msv-blue-20};
    --msv-account-profile-info-message-border: transparent;
}

.ms-account-profile {
    width: $msv-width-full;

    &__info {
        @include add-icon($msv-information);
        @include font-body-regular-m();
        @include vfi();
        background-color: var(--msv-account-profile-info-message-bg);
        border: 1px solid var(--msv-account-profile-info-message-border);
        padding: $msv-account-profile-info-padding;
        margin-bottom: $msv-account-profile-info-margin-bottom;
        border-radius: 10px;

        &::before {
            font-size: $msv-font-size-ml;
            padding-right: 10px;
            top: 2px;
            position: relative;
            vertical-align: top;
            color: $msv-blue;
        }
    }

    &-wrapper {
        margin-right: $msv-account-profile-section-margin-right;
        float: left;
    }

    &__attributes-wrapper {
        float: left;
    }

    .ms-account-profile-wrapper {
        margin-right: $msv-account-profile-margin-right;
        width: calc(5 * #{$msv-column-width-s});

        @media screen and (max-width: $msv-breakpoint-m) {
            padding-bottom: 40px;
        }

        .ms-account-loyalty-tile__heading {
            color: var(--msv-address-heading-font-color);
            padding-bottom: 24px;
            // border-bottom: 0.5px solid $msv-gray-50;
            font-family: $msv-bold-font-family;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.84px;
            // @include font-heading-h3-l();
            // padding-bottom: $msv-account-profile-heading-padding-bottom;
            // border-bottom: 0.5px solid $msv-gray-50;
            // color: var(--msv-account-landing-tile-heading-color);
            // margin-bottom: $msv-account-profile-heading-margin-bottom;

            &::before {
                display: none;
            }
        }

        .ms-account-profile__section {
            margin-bottom: $msv-account-profile-section-margin-bottom;

            &-heading {
                @include font-heading-h5-l();
                margin-bottom: $msv-account-profile-section-heading-margin-bottom;
            }

            &-description {
                @include font-body-regular-m();
                margin-bottom: $msv-account-profile-description-margin-bottom;
            }

            &-preferences {
                margin-top: $msv-account-profile-section-preferences-margin-top;

                .ms-account-profile__section-heading {
                    @include font-heading-h3-l();
                    padding-bottom: $msv-account-profile-section-preferences-heading-padding-bottom;
                    border-bottom: 0.5px solid $msv-gray-50;
                    // margin-bottom: $msv-account-profile-section-preferences-heading-margin-bottom;
                }

                .ms-account-profile__preference {
                    &-heading {
                        @include font-heading-h5-l();
                        margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                    }

                    &-description {
                        @include font-body-regular-m();
                    }
                }

                .ms-account-profile__preference-web-tracking {
                    margin-top: $msv-account-profile-section-preferences-web-tracking-margin-top;
                }
            }

            &-links {
                .ms-account-profile__section-link {
                    @include button-link($color: $msv-gray-20);
                    @include link-regular-m();
                    border-radius: 0;
                    padding: $msv-account-profile-links-padding;

                    &-disable {
                        @include link-regular-m();
                        border-radius: 0;
                        padding: $msv-account-profile-links-padding;
                        pointer-events: none;
                        color: var(--msv-account-profile-link-disabled-color);
                    }
                }
            }
        }
    }

    .ms-account-profile__attributes-wrapper {
        width: 100%;

        .ms-account-profile__attributes {
            display: flex;
            flex-wrap: wrap;
            column-gap: 5%;

            &-tile__heading {
                // @include font-heading-h3-l();
                // margin-bottom: $msv-account-profile-heading-margin-bottom;
                // border-bottom: 0.5px solid $msv-gray-50;
                color: var(--msv-address-heading-font-color);
                font-family: $msv-bold-font-family;
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: 0.84px;
                padding-bottom: $msv-account-profile-heading-padding-bottom;
                color: var(--msv-account-landing-tile-heading-color);
                flex: 1 0 100%;
            }

            &__section {
                flex: 1 0 100%;
                margin-bottom: $msv-account-profile-attributes-section-margin-bottom;

                @media screen and (min-width: $msv-breakpoint-m) {
                    flex: 1 0 40%;
                }

                &-heading {
                    @include font-heading-h5-l();
                    // margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                }

                &-description {
                    @include font-body-regular-m();
                }

                &:last-of-type {
                    flex: 1 0 100%;
                }

            }

            &-edit-button {
                // @include button-link($color: $msv-gray-20);
                // @include link-regular-m();
                @include primary-button-dark;
                border-radius: 0;
                padding: $msv-account-profile-links-padding;
                margin-top: 20px;
                width: 100%;

                @media screen and (min-width: $msv-breakpoint-m) {
                    // justify-self: self-start;
                    width: fit-content;
                }

                &-disabled {
                    @include link-regular-m();
                    border-radius: 0;
                    padding: $msv-account-profile-links-padding;
                    pointer-events: none;
                    color: var(--msv-account-profile-link-disabled-color);
                }
            }

            &_input-edit-false, &_select-edit-false {
                background: transparent;
                border: 1px solid transparent;
                outline: none;
                color: $msv-gray-20;
                appearance: none;
                padding: 8px 0;
                margin-top: 5px;
                width: 100%;

                @include font-body-regular-m();
                font-family: $msv-primary-font-family;
            }

            &_input-edit-true, &_select-edit-true {
                @include form-input-box();
                margin-top: 5px;
                padding: 8px;
                font-size: 16px;
                line-height: 24px;
            }

            &_select-edit-false {
                padding: 0;
            }

            &-save-button {
                @include primary-button-dark();
                margin-top: 20px;
                // margin-right: $msv-account-profile-attributes-section-save-button-margin-right;
                
                @media screen and (min-width: $msv-breakpoint-m) {
                    width: fit-content;
                }
            }

            &-cancel-button {
                @include secondary-button-dark();
                width: 100%;
                margin-top: $msv-account-profile-cancel-button-margin-top;
                
                @media screen and (min-width: $msv-breakpoint-m) {
                    width: fit-content;
                    margin-top: auto;
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        display: block;

        .ms-account-profile-wrapper, .ms-account-profile__attributes-wrapper {
            width: $msv-width-full;
        }
    }
}

.ms-account-profile,
.ms-account-profile .ms-account-profile__attributes-wrapper .ms-account-profile__attributes__section {
    &__toggle-wrapper {
        margin-top: $msv-account-profile-section-preferences-toggle-margin-top;

        @include font-body-regular-m();
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-enable-button {
        @include toggle-button-on();
    }

    &__toggle-disable-button {
        @include toggle-button-off();
    }

    &__toggle-disabled {
        color: var(--msv-account-profile-link-disabled-color);
        pointer-events: none;
    }
}

.ms-account-profile .ms-account-profile__attributes-wrapper .ms-account-profile__attributes__section.ms-account-profile__attributes-VAT_Number {
    margin-bottom: $msv-account-profile-vat-margin-bottom;
}
