$non-mobile: 600px;

// Mediagallery
.ms-buybox {
    @media (min-width: $msv-breakpoint-s) {
        &__media-gallery {
            display: block;
            flex-basis: 45%;
            min-width: 40%;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: 40px;
        }
    }

    //mobile vp
    @media (max-width: ($msv-breakpoint-m)-1) {
        &__media-gallery {
            padding-right: 0;
        }
    }

    //tablet vp
    @media (min-width: $msv-breakpoint-m) and (max-width: ($msv-breakpoint-l)-1) {
        &__media-gallery {
            padding-right: 0;
        }
    }

    @media (min-width: ($msv-breakpoint-l)+1) {
        &__media-gallery {
            padding-right: 33px;

            .ms-media-gallery {
                &.vertical {
                    .msc-ss-carousel-vert-slide {
                        .ms-media-gallery__thumbnail-item {
                            margin-right: 12px;
                            margin-bottom: 12px;
                            height: 100%;

                            &:nth-of-type(2n) {
                                margin-left: 20px;
                            }

                            button.msc-ss-carousel-vert-button-wrapper {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .msc-ss-carousel-vert-item {
                    width: auto;
                }
            }
        }
    }

    @media (min-width: ($msv-breakpoint-l)+1) and (max-width: ($msv-breakpoint-lg)-1) {
        .ms-media-gallery .msc-ss-carousel-vert-item {
            width: auto;
        }
    }

    @media (min-width: $msv-breakpoint-xl) {
        &__media-gallery {
            .ms-media-gallery {
                .msc-ss-carousel-vert-item {
                    width: auto;
                }
            }
        }
    }

    // Media gallery specific implementation
    &__media-gallery {
        width: 100%;

        .ms-media-gallery {
            height: 100%;
            // max-height: 80dvh;
            display: flex;
            flex-direction: row-reverse;

            .ms-media-gallery__thumbnails-container {
                width: 15%;
                overflow-y: auto;
                max-height: 600px;

                .ms-media-gallery__thumbnail-item {
                    margin-right: 12px;
                    margin-bottom: 12px;
                    height: 100%;
                }
            }

            .ms-media-gallery__carousel {
                width: 85%;
            

                .msc-carousel__inner {
                    height: 100%;
                }

                .msc-carousel__indicators {
                    bottom: 1.3rem;

                    li {
                        margin: 0 12px 12px 0px;
                        border: 0;
                        background: #AEAEAE;
                        box-shadow: 0px 1px 9px 0px #00000040;

                        &.active {
                            background: #FFF;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: ($msv-breakpoint-m)) {
        &__media-gallery {
            width: 100%;

            .ms-media-gallery {
                // max-height: 80dvh;
                display: flex;
                flex-direction: row-reverse;

                .ms-media-gallery__thumbnails-container {
                    width: 15%;

                    .msc-ss-carousel-vert {
                        .msc-ss-carousel-vert-strip {
                            .msc-ss-carousel-vert-slide {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .ms-media-gallery__thumbnail-item {
                                    margin-right: 12px;
                                    margin-left: 0;
                                    margin-bottom: 12px;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .ms-media-gallery__carousel {
                    width: 85%;
                    margin-left: 20px;

                    .msc-carousel__inner {
                        height: 100%;
                    }

                    .msc-carousel__indicators,
                    .msc-carousel__control__next,
                    .msc-carousel__control__prev {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: ($msv-breakpoint-m)) {
        &__media-gallery {
            .ms-media-gallery {
                .ms-media-gallery__thumbnails-container {
                    display: none;
                }

                .ms-media-gallery__carousel {
                    width: 100%;

                    // .msc-carousel__control__next,
                    // .msc-carousel__control__prev {
                    //     display: none;
                    // }
                }
            }
        }
    }
}

// Buybox
.ms-buybox {
    flex-flow: column;

    @media (min-width: $non-mobile) {
        flex-flow: row;
    }

    .msc-alert {
        display: none;
    }

    &__content {
        // width: initial; //Reset

        @media (min-width: $non-mobile) {
            padding-left: 36px;
        }

        >.color-name {
            // @include font-body-light-m();
            font-family: 'Conv_EuropaLight';
            padding: 12px 0px;
            margin-bottom: 12px;
            display: block;
            font-size: 12px;
            text-transform: uppercase;

            @media (min-width: $non-mobile) {
                margin-bottom: 24px;
            }
        }

        .ms-buybox__product-title {
            font-family: inherit;
            font-size: 16px;
            font-weight: 400;
            margin: 4px 0 0 0;
            padding: 0;
        }

        .msc-buybox__ratings-section {
            display: none;
        }

        .msc-buybox__price-section {
            margin: 4px 0 0 0;
            padding: 0;
            border: 0;

            &-text {
                display: none;
            }
        }

        .msc-buybox__bulk-purchase-section {
            border: 0;
            border-radius: 0;
        }

        .ms-buybox__quantity {
            margin: 0;
            padding: 0;

            .ms-buybox__product-quantity-label {
                margin: 0;
                padding: 0;
            }

            .quantity_text_message {
                display: none;
                margin: 0 0 12px 0;
                padding: 0;

                p {
                    font-family: "Rubik-Regular";
                    font-size: 12px;
                }
            }

            .quantity {
                justify-content: space-between;
                gap: 12px;

                @media (min-width: $non-mobile) {
                    gap: 10px;
                }

                .quantity__controls {
                    width: 62px;
                    max-width: 80px;
                    height: 40px;
                    margin: 0;
                    padding: 0;
                    border-radius: 0;
                    border-color: #D2D2D2;
                    transition: border-color 0.25s;

                    @media (min-width: $non-mobile) {
                        border-color: #D9D9D9;
                    }

                    @media (min-width: $non-mobile) {
                        width: 100px;
                        max-width: 100px;
                        height: 44px;
                    }

                    &:focus {
                        border-color: #D2D2D2;
                    }

                    &:hover {
                        border-color: #000;
                    }

                    &.decrement::after {
                        content: "\f1ae"; //REF: https://remixicon.com/icon/subtract-line
                        font-family: "Remix Icon";
                        color: #000;
                        font-size: 12px;
                        margin: -3px 0 0 0;
                    }

                    &.increment::after {
                        content: "\ea12"; //REF: https://remixicon.com/icon/arrow-left-line
                        font-family: "Remix Icon";
                        color: #000;
                        font-size: 12px;
                        margin: -3px 0 0 0;
                    }
                }

                .quantity-input {
                    flex: 1;
                    height: 40px;
                    margin: 0;
                    padding: 0;
                    border-radius: 0;
                    border-color: #D2D2D2;
                    transition: border-color 0.25s;

                    @media (min-width: $non-mobile) {
                        height: 44px;
                    }

                    &:hover,
                    &:focus {
                        border-color: #000;
                    }
                }
            }
        }

        .ms-buybox__add-to-cart-container {
            margin: 12px 0 0 0;
            padding: 0;

            @media (min-width: $non-mobile) {
                margin: 15px 0 0 0;
            }

            .msc-alert {
                margin: 0;
                padding: 0;
            }

            .msc-add-to-cart {
                color: #FFF;
                background-color: rgba(0, 0, 0, 0.9);
                margin: 0;
                padding: 0;
                border: 0;
                width: 100%;
                min-width: auto;
                height: 52px;
                transition: background-color 0.25s;

                @media (min-width: $non-mobile) {
                    height: 52px;
                }

                &:hover {
                    background-color: #000;
                }
            }
        }

        .ms-buybox__add-to-wishlist-container {
            margin: 4px 0 0 0;
            padding: 0;

            .msc-add-to-wishlist {
                font-family: inherit;

                &:before,
                &:hover:before {
                    order: 1;
                    margin: 0 0 0 6px;
                    font-family: 'icomoon';
                    content: "";
                    color: #000;
                    font-weight: 400;
                }

                &.msc-add-to-wishlist__removing:before,
                &.msc-add-to-wishlist__removing:hover:before {
                    content: "";
                    color: #e6302e;
                }
            }
        }

        .msc-add-to-order-template-icon,
        .msc-add-to-wishlist {
            padding-left: 0;
            padding-right: 0;
        }

        .msc-buybox__description-text {
            display: none;
            padding-top: $msv-buybox-description-text-mobile-padding-top;
        }

        .msc-buybox__shop-description {
            .ms-buybox__shopsimilarlooks {
                padding-right: $msv-buybox-shopsimilarlooks-mobile-padding-right;
            }

            .ms-buybox__shopsimilardescriptions {
                padding-left: $msv-buybox-shopsimilardescriptions-mobile-padding-left;
            }
        }
    }

    .ms-buybox__configure {
        @media (min-width: $non-mobile) {
            margin: 44px 0 0 0;
        }

        .ms-buybox__dropdown {
            margin: 22px 0 0;
            padding: 0;

            @media (min-width: $non-mobile) {
                margin: 22px 0 0;
            }

            &:first-child .msc-swatch-container {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-bottom: 14px;

                @media (min-width: $non-mobile) {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                }

                &__item {
                    height: 35px;
                    border: 1px solid #d1d1d1 !important;
                    background-color: #FFF;
                    transition: border 0.25s;
                    font-size: 14px;
                    min-width: 18%;

                    @media (min-width: $non-mobile) {
                        height: 37px;
                    }

                    &__selected,
                    &:hover {
                        border: 1px solid #000 !important;
                        font-size: 14px !important;
                    }
                }
            }

            &:not(:first-child) .msc-swatch-container {
                grid-template-columns: repeat((8 / 1), 1fr); //NOTE: Mobile use 8 grid/rows

                @media (min-width: $non-mobile) {
                    grid-template-columns: repeat(10, 1fr);
                }

                &__item {
                    aspect-ratio: 1 / 1;
                    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.15);
                    transition: border 0.25s;

                    &__selected,
                    &:hover {
                        border: 1px solid #000 !important;
                    }
                }
            }

            &-quantity-label {
                font-family: inherit;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0 0 12px 0;
                padding: 0;
            }

            .msc-dropdown__select {
                border-radius: 0;
                border-color: #D2D2D2;
                transition: border 0.25s;
                font-size: 14px !important;

                &:hover {
                    border: 1px solid #000 !important;
                    font-size: 14px !important;
                }
            }

            .msc-alert {
                margin: 12px 0 0 0;
                padding: 0;
                color: var(--msv-buybox-msc-alert-color) !important;
            }
        }

        .msc-swatch-container {
            display: grid;
            gap: 14px;

            @media (min-width: $non-mobile) {
                gap: 10px;
            }

            &__item {
                //Resets
                margin: 0;
                padding: 0;
                width: 100%;
                min-width: 100%;
                height: 100%;
                min-height: 100%;
                font-family: inherit;
                font-weight: inherit;

                &__selected {
                    font-family: inherit;
                    font-weight: inherit;
                }
            }
        }
    }

    .msc-price:has(.msc-price__discount) {
        flex-direction: row;
        align-items: center;

    }

    .msc-price,
    .msc-price__actual,
    .msc-price__strikethrough,
    .msc-price__discount {
        margin: 0;
        margin-right: 12px;
        padding: 0;
        font-family: inherit;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;

        @media (min-width: $non-mobile) {
            font-size: 32px;
        }
    }

    .msc-price__discount__member {
        @include font-body-regular-m();
        color: $msv-white;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    // HIDES STYLE DIMENSION

    .ms-buybox__dropdown:has([for="ms-buybox__dropown-quantity-input-4"]) {
        display: none !important;
    }
}