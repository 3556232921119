@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/01-tools/layout.scss";
@import "../common/02-generic/image.scss";
@import "../common/02-generic/button.scss";
@import "../common/00-settings/icons.scss";
// @import "../common/02-generic/reset.scss";

// Variables
$ms-pre-footer-padding-left: 5px;
$ms-pre-footer-padding-right: 5px;
$ms-pre-footer-padding-top: 36px;
$ms-pre-footer-padding-bottom: 36px;
$ms-pre-footer-tablet-padding-left: 5px;
$ms-pre-footer-tablet-padding-right: 5px;
$ms-pre-footer-item-margin-right: 20px;
$footer-item-padding-desktop: 20px;
$ms-main-footer-item-margin-right: 20px;
$footer-post-footer-height: 110px;
$ms-text-block-margin: 30px;
$ms-text-block-margin-top: 4px;
$ms-main-footer-padding-bottom: 44px;
$ms-main-footer-padding-top: 24px;
$ms-main-footer-list-item-padding: 0;
$ms-accordion-margin: 15px;
$ms-accordion-padding-left: 0;
$ms-accordion-min-height: 85px;
$ms-post-footer-link-font-weight-400: 400;
$ms-mobile-post-footer-list-padding-left: 0;
$ms-mobile-post-footer-list-padding-right: 0;
$ms-post-footer-padding-top: 65px;
$ms-post-footer-padding-bottom: 55px;
$ms-post-footer-list-item-padding-left: 10px;
$ms-post-footer-list-item-padding-right: 10px;
$ms-post-footer-list-item-tablet-mobile-padding: 0 10px;
$ms-footer-social-media-margin-right: 10px;
$ms-footer-copyrights-height: 40px;
$ms-footer-footer-link-padding-bottom: 35px;
$ms-footer-footer-link-padding-top: 35px;
$ms-tablet-copyright-height: 40px;
$ms-footer-desktop-social-media-margin-right: 10px;
$ms-footer-subscribe-text-margin: 20px 0 28px 0;
$ms-footer-subscribe-submit-margin: 20px 0 20px 0;
$ms-footer-social-media-image-height: 64px;
$ms-footer-logo-height: 28px;
$ms-footer-logo-width: 220px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 112px;
$footer-back-to-top-padding: 10px 0;
$footer-back-to-top-margin: 3px;
$footer-heading-icon-margin: 12px;

:root {
    //Backgrounds
    --msv-footer-bg-color: var(--msv-bg-color);
    --msv-post-footer-bg-color: #{$msv-gray-20};

    // Headings
    --msv-footer-heading-font-color: var(--msv-font-primary-color);

    //items
    --msv-footer-font-color-secondary: var(--msv-font-secondary-color);

    //Links
    --msv-footer-link-font-color: var(--msv-font-primary-color);

    //Border
    --msv-footer-border-bottom-color: #{$msv-secondary};

    //Accordion
    --msv-accordion-item-drawer-color: #{$msv-gray-20};
}

footer {
    background-color: var(--msv-footer-bg-color);
}

.ms-footer {
    padding: 0;

    .post-footer-container {
        background: var(--msv-post-footer-bg-color);
        padding: 6px 40px !important;

        .row {
            align-items: center;
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            .col-md-4:nth-of-type(1) {
                order: 1;
            }

            .col-md-4:nth-of-type(2) {
                order: 3;
                padding-bottom: 12px;
            }

            .col-md-4:nth-of-type(3) {
                order: 2;
            }

            .col-md-4:nth-of-type(4) {
                order: 4;
            }
        }


        .ms-text-block {
            padding: 0;
            margin: 0;

            p {
                @include font-body-regular-m();
                line-height: 1.2;
            }
        }

        ul {
            &.Social__media {
                display: flex;
                justify-content: end;

                @media (max-width: $msv-breakpoint-m - 1) {
                    justify-content: center;
                    margin: 20px 0px;
                }

                .ms-footer__item {
                    padding: 4px 6px;

                    &.instagram {
                        .ms-footer__link {

                            &::before {
                                @include msv-icomoon-icon();
                                content: $ico-Instagram;
                                color: $msv-gray-900;
                                font-size: 28px;

                            }

                            // @include add-icon($msv-custom-insta, before, $msv-outline-icon-weight);
                        }
                    }

                    &.facebook {
                        .ms-footer__link {

                            &::before {
                                @include msv-icomoon-icon();
                                content: $ico-Facebook;
                                color: $msv-gray-900;
                                font-size: 28px;
                            }

                            // @include add-icon($msv-custom-facebook, before, $msv-outline-icon-weight);
                        }
                    }

                    &.linkdin {
                        .ms-footer__link {
                            &::before {
                                @include msv-icomoon-icon();
                                content: $ico-LinkedIn;
                                color: $msv-gray-900;
                                font-size: 28px;
                            }

                            // @include add-icon($msv-custom-linkdin, before, $msv-outline-icon-weight);
                        }
                    }

                    &.tiktok {
                        .ms-footer__link {
                            &::before {
                                @include msv-icomoon-icon();
                                content: $ico-TikTok;
                                color: $msv-gray-900;
                                font-size: 28px;
                            }

                            // @include add-icon($msv-custom-tiktok, before, $msv-outline-icon-weight);
                        }
                    }

                    .ms-footer__link {
                        min-height: 25px;

                        &::before {
                            font-size: 32px;
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .ms-footer-logo {
        .msc-image-container {
            @include image($ms-footer-logo-width, $ms-footer-logo-height);
        }
    }

    .ms-accordion {
        height: 100%;

        .ms-accordion-item-section {
            margin-left: $ms-accordion-margin;
            margin-right: $ms-accordion-margin;
        }

        .drawer__button {
            width: 100%;
            background-color: var(--msv-footer-bg-color);
            border: none;
            outline: none;
            border-top: 0.5px solid var(--msv-footer-border-bottom-color);
            padding-left: $ms-accordion-padding-left;
            min-height: $ms-accordion-min-height;

            &[aria-expanded="true"] {
                @include add-icomoon-icon($ico-minus, after);

                &:after {
                    color: var(--msv-accordion-item-drawer-color);
                    line-height: $msv-line-height-s;
                    font-size: 10px !important;
                    float: right;
                }
            }

            &[aria-expanded="false"] {
                @include add-icomoon-icon($ico-plus, after);

                &:after {
                    color: var(--msv-accordion-item-drawer-color);
                    line-height: $msv-line-height-s;
                    font-size: 10px !important;
                    float: right;
                }
            }

            &:focus {
                border: 0.5px solid #{$msv-blue};
            }

            &:focus:not(:focus-visible) {
                outline: none;
            }
        }

        .drawer__buttontext {
            float: left;

            @include font-body-bold-s();
        }

        .ms-footer__list-item {
            line-height: $msv-line-height-xl;
        }
    }

    .ms-footer__item::before {
        float: left;
        margin-top: $ms-text-block-margin-top;
    }

    .ms-footer__list {
        &.pre-footer {
            border-top: 0.5px solid var(--msv-footer-border-bottom-color);

            .ms-footer__list-item {
                &.find-a-store {
                    &:first-child {
                        .ms-footer__heading__title-icon::before {
                            @include msv-icon();
                            content: $msv-location;
                            margin-top: 4px;
                        }
                    }
                }

                &.customer-service {
                    &:first-child {
                        .ms-footer__heading__title-icon::before {
                            @include msv-icon();
                            content: $msv-Phone;
                            margin-top: 4px;
                        }
                    }
                }

                &.need-help {
                    &:first-child {
                        .ms-footer__heading__title-icon::before {
                            @include msv-icon();
                            content: $msv-Chat;
                            margin-top: 4px;
                        }
                    }
                }

                &.general-info {
                    &:first-child {
                        .ms-footer__heading__title-icon::before {
                            @include msv-icon();
                            content: $msv-question-circle;
                            margin-top: 4px;
                        }
                    }
                }
            }

            .ms-footer__list {
                .ms-footer__list-item {
                    &:nth-child(2) {
                        margin-left: $ms-text-block-margin;
                    }
                }
            }

            .ms-footer__heading {
                display: flex;

                &__title {
                    margin-left: $footer-heading-icon-margin;
                }
            }
        }

        &.post-footer {
            .ms-footer__link {
                @include font-body-regular-s();
                color: var(--msv-footer-font-color-secondary);

                @include vfi();
            }

            .ms-footer-copyright {
                @include font-body-bold-s();
                color: var(--msv-footer-font-color-secondary);
            }

            .ms-footer__list {
                display: flex;
            }

            .ms-footer__list.ms-footer-links {
                .ms-footer__list-item {
                    padding-left: $ms-post-footer-list-item-padding-left;
                    padding-right: $ms-post-footer-list-item-padding-right;
                }

                .ms-footer__list-item:nth-child(1) {
                    border-right: 1px solid var(--msv-footer-bg-color);
                }
            }

            .ms-footer-social-media {
                .ms-footer__link {
                    height: $ms-footer-social-media-image-height;
                    width: $ms-footer-social-media-image-height;

                    &__text {
                        display: none;
                    }
                }

                .ms-footer__item {
                    .msc-image-container {
                        @include image($ms-footer-social-media-image-height, $ms-footer-social-media-image-height);
                    }
                }

                .msc-main_image {
                    max-width: fit-content;
                    min-width: fit-content;
                }
            }
        }
    }

    .ms-subscribe {
        background: transparent;

        .ms-subscribe__details {
            color: var(--msv-footer-link-font-color);
            padding: 0;
            width: 100%;

            &__text {
                font-weight: $ms-post-footer-link-font-weight-400;
                margin: $ms-footer-subscribe-text-margin;
                color: $msv-gray-20;
            }

            &__form {
                display: block;

                &__email {
                    border-bottom: 1px solid $msv-gray-50;
                    color: var(--msv-footer-link-font-color);
                    width: 100%;

                    &::placeholder {
                        color: $msv-gray-40;
                    }
                }

                &__submit {
                    @include secondary-button-light();

                    margin: $ms-footer-subscribe-submit-margin;
                }
            }

            &__heading {
                display: none;
            }
        }
    }

    .ms-back-to-top {
        @include card-button-with-icon-only();
        @include add-icon($msv-ChevronUp, before);

        position: fixed;
        opacity: 0;
        bottom: $footer-back-to-top-bottom;
        right: $footer-back-to-top-right;

        &:hover,
        &:focus {
            opacity: 0;
            box-shadow: $msv-depth8;
            text-decoration: none;
        }

        &:focus {
            outline: 1px dashed $msv-white;
            border: none;
            outline-offset: -4px;
        }

        &::before {
            font-size: $msv-icon-size-xs;
        }
    }

    @media (min-width: 0px) {
        font-weight: $msv-font-weight-normal;

        .ms-accordion {
            display: none;
        }

        .ms-footer__link {
            @include vfi();
            @include font-body-regular-s();
        }

        .ms-footer__list {
            &.pre-footer {
                padding-top: $ms-pre-footer-padding-top;
                padding-bottom: $ms-pre-footer-padding-bottom;

                .ms-footer__item {
                    padding-top: 0;
                }

                .ms-footer__list-item {
                    .ms-footer__heading {
                        &__title {
                            @include font-heading-h6-l();
                            color: var(--msv-footer-heading-font-color);
                        }
                    }

                    &.pre-footer {
                        width: calc(2 * #{$msv-column-width-s});
                        margin-right: $ms-pre-footer-item-margin-right;
                        display: inline-flex;
                        flex-direction: column;
                    }
                }
            }

            &.main-footer {
                // border-top: 0.5px solid var(--msv-footer-border-bottom-color);
                padding-bottom: $ms-main-footer-padding-bottom;
                display: flex;
                justify-content: space-between;
                padding-left: 40px;
                padding-right: 40px;
                max-width: 1440px;
                margin: auto;

                .ms-footer__item {
                    padding-top: $footer-item-padding-desktop;
                }

                li.ms-footer__list-item {
                    width: calc(2 * #{$msv-column-width-s});
                    padding-top: $ms-main-footer-padding-top;
                    margin-right: $ms-main-footer-item-margin-right;
                    display: inline-flex;
                    flex-direction: column;

                    &:last-child {
                        width: calc(2.5 * #{$msv-column-width-s});
                    }

                    .ms-footer__heading__title {
                        @include font-body-bold-m();
                    }

                    .ms-text-block {

                        p {
                            @include font-body-regular-m();
                            // line-height: 1.2;
                        }
                    }

                    ul {
                        display: inline;
                        border-top: none;

                        li.ms-footer__list-item {
                            width: 100%;
                            padding-top: $ms-main-footer-list-item-padding;
                        }
                    }
                }
            }

            &.post-footer {
                display: flex;
                height: $footer-post-footer-height;
                justify-content: space-between;

                .ms-footer__list-item.post-footer {
                    align-self: center;
                }

                .ms-footer__list {
                    display: flex;
                }

                .ms-footer-social-media {
                    .ms-footer__link {
                        margin-right: $ms-footer-social-media-margin-right;
                    }
                }

                .ms-footer__list.ms-footer-copyright,
                .ms-footer__list.ms-footer-links {
                    height: $ms-footer-copyrights-height;

                    .ms-footer__list-item {
                        align-self: center;
                    }
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        .ms-footer__link {
            @include font-body-regular-s();
            color: var(--msv-footer-link-font-color);
            line-height: $msv-line-height-xl;

            @include vfi();
        }

        .ms-footer__list {

            &.pre-footer {
                display: flex;
                flex-flow: wrap;
                padding-top: $ms-pre-footer-padding-top;
                padding-right: $ms-pre-footer-tablet-padding-right;
                padding-left: $ms-pre-footer-tablet-padding-left;

                .ms-footer__list-item {
                    &.pre-footer:nth-child(n + 1) {
                        padding-bottom: $ms-pre-footer-padding-bottom;
                        width: 50%;
                    }
                }

                .ms-footer__heading {
                    &__title {
                        @include font-heading-h6-s();
                    }
                }
            }

            &.post-footer {
                padding-bottom: $ms-post-footer-padding-bottom;
                padding-top: $ms-post-footer-padding-top;

                .ms-footer__list-item.post-footer {
                    display: flex;
                    justify-content: center;
                    padding-left: 25%;
                    padding-right: 25%;
                }

                .ms-footer__list.ms-footer-copyright {
                    height: $ms-tablet-copyright-height;

                    .ms-footer__list-item {
                        align-self: center;
                    }
                }

                .ms-footer-social-media {
                    .ms-footer__link {
                        @include vfi();
                        margin-right: $ms-footer-desktop-social-media-margin-right;
                    }
                }

                .ms-footer__list.ms-footer-links {
                    height: $msv-line-height-m;
                    padding-bottom: $ms-footer-footer-link-padding-bottom;
                    padding-top: $ms-footer-footer-link-padding-top;

                    .ms-footer__list-item {
                        align-self: center;
                    }

                    .ms-footer__link {
                        line-height: $msv-line-height-xl;
                        padding: $ms-post-footer-list-item-tablet-mobile-padding;
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        ul.ms-footer__list.main-footer {
            display: flex;
            flex-wrap: wrap;

            li.ms-footer__list-item.main-footer {
                width: 30%;

                &:has(.payment-methods) {
                    width: 100%;
                }
            }
        }

        .ms-subscribe {
            .ms-subscribe__details {
                width: calc(4 * #{$msv-column-width-m});
                margin: 0;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m - 1) {
        .ms-accordion {
            .ms-accordion-item {
                &__drawer {
                    .drawer__buttontext {
                        font-weight: 500;
                    }
                }
            }
        }

        .ms-footer__list {

            &.main-footer {
                display: none;
            }

            &.pre-footer {
                flex-direction: column;
            }

            &.post-footer {
                .ms-footer__list-item.post-footer {
                    padding-left: $ms-mobile-post-footer-list-padding-left;
                    padding-right: $ms-mobile-post-footer-list-padding-right;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) {
        .ms-back-to-top[aria-hidden="false"] {
            opacity: 0.41;

            &:hover,
            &:focus {
                opacity: 0.8;
            }
        }
    }
}

//----- UNIQUE MODULE CLASSES

ul.payment-methods.ms-footer__list {
    text-align: end;

    li.ms-footer__list-item.payment-methods {
        width: 50px !important;
        margin: 0;
        margin-left: 12px;

        .payment-method-image {
            height: fit-content;
            padding: 0;
        }
    }
}

footer .default-container .default-container.mobile-footer-container {
    padding: 20px 30px;

    .ms-accordion.mobile-footer-accordion {
        display: block;
        padding: 20px 0px;

        .ms-accordion-item-section {
            margin: 0;

            .ms-accordion-item__drawer {
                border-top: none;

                .drawer__button {
                    min-height: 60px;
                    height: 65px;
                }
            }
        }

        .ms-accordion-item-content {
            padding: 0;

            .ms-text-block {
                margin-bottom: 12px;
                padding-bottom: 0px;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    ul.ms-footer__list {
        display: flex;
        justify-content: center;

        .payment-method-image {
            width: fit-content;
            padding: 0;
        }
    }

    @media (min-width: $msv-breakpoint-m) {
        display: none;
    }
}